import React, {useEffect, useState, useMemo, useRef} from "react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import {
    Grid,
    Button,
    useTheme,
    Typography,
    TextField
} from "@mui/material";
import Swal from "sweetalert2";
import {$crud} from "./factories/CrudFactory";
import {Circle, GoogleMap, OverlayView, Polygon as PolygonComponent, Marker ,useLoadScript} from "@react-google-maps/api";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {getBounds, getDistance, getNavigateUrl} from "./helpers";
import {LatLngLiteral} from "@googlemaps/google-maps-services-js";
import moment from "moment-timezone";

export default function ParkingCheckout() {
    const history = useHistory();
    const theme = useTheme();
    const location = useLocation();
    const {locationId} = useParams();
    const searchParams = new URLSearchParams(location.search);

    const [gMap, setGMap] = useState<google.maps.Map>();
    const [zoom, setZoom] = useState(20);
    const [mapTypeId, setMapTypeId] = useState<string>(google.maps.MapTypeId.SATELLITE);
    const [locations, setLocations] = useState<any>([]);
    const polygonRefs = useRef<Record<number, google.maps.Polygon>>({});

    const mapName = searchParams.get("mapName");
    const checkInDateTime = searchParams.get("checkInDateTime");
    const checkOutDateTime = searchParams.get("checkOutDateTime");

    const [params, setParams] = useState<any>({});
    const [locationData, setLocationData] = useState<any>(null);
    const [center, setCenter] = useState({
        lat: 32.940454,
        lng: -96.912492
    });
    const libraries = useMemo<Libraries>(() => ["geometry", "drawing", "places"], []);
    const {isLoaded} = useLoadScript({
        id: "birdview",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
        libraries
    });

    useEffect(() => {
        if (locationId)
            getLocationById();
    }, []);

    const getLocationById = async () => {
        try {
            const { data: { location: lD } } = await $crud.get(`locations/${locationId}`);
            setLocationData(lD);
        } catch (e) {
            console.log(e);
        }
    };

    const confirmBooking = async () => {
        const warnings: string[] = [];

        if (!params.firstName || params.firstName?.trim() === "")
            warnings.push('Please enter First name.');

        if (!params.mobilePhone || params.mobilePhone?.trim() === "")
            warnings.push('Please enter Mobile phone.');

        if (!params.email || params.email?.trim() === "")
            warnings.push('Please enter Email.');


        if(warnings.length){
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const {type, data: {bookingId}} = await $crud.post("parking/create", {
                ...params,
                mapId: locationData?.address._id,
                locationId,
                checkInDateTime,
                checkOutDateTime,
                directionToParking: await getNavigateUrl(locationData)
            });

            if(type === "success")
                history.push(`/purchase-parking/confirmation/${bookingId}`)
        }catch (e) {
            console.log(e);
        }
    };

    const openBirdEye = async () => {
        try{
            if(locationData?.shape === "circle"){
                setCenter({
                    lat: JSON.parse(locationData?.circleCenter)?.lat,
                    lng: JSON.parse(locationData?.circleCenter)?.lng
                });
            }else if(locationData?.shape === "marker"){
                setCenter({
                    lat: JSON.parse(locationData?.bounds)?.lat,
                    lng: JSON.parse(locationData?.bounds)?.lng
                });
            } else{
                setCenter({
                    lat: locationData.Latitude4,
                    lng: locationData.Longitude4
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getLocations = async () => {
        try{
            const {data: {locations}} = await $crud.get("locations", {addressId: locationData?.address?._id, locationId});
            setLocations(locations);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if(locationData){
            openBirdEye();
            getLocations();
        }
    }, [locationData]);

    const googleMap = isLoaded && center && <GoogleMap
        onLoad={setGMap}
        id="BirdEyeViewMap"
        center={center}
        zoom={zoom}
        mapTypeId={mapTypeId}
        onMapTypeIdChanged={() => gMap && setMapTypeId(gMap.getMapTypeId()!)}
        onZoomChanged={() => gMap && setZoom(gMap.getZoom()!)}
        mapContainerStyle={
            {
                width: "100%",
                height: "100%"
            }
        }
        clickableIcons={false}
        tilt={0}
        options={{
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeId: "satellite"
        }}
    >

        {
            locations.map((location, index) => {
                const {bounds, id, style, shape} = location;
                let heading, labelBounds;
                const fillColor = "#ffffcc";
                if(!["circle", "marker"].includes(shape)) {
                    // @ts-ignore
                    const [point1, point2] = bounds!.reduce<LatLngLiteral[]>((points, coords, i, array) => {
                        const nextPoint = array[i + 1] ?? array[0];
                        const dis = getDistance(coords.lat, coords.lng, nextPoint.lat, nextPoint.lng);
                        return !points.length || dis > getDistance(points[0].lat, points[0].lng, points[1].lat, points[1].lng) ? [coords, nextPoint] : points;
                    }, []);

                    heading = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(point1.lat > point2.lat ? point1 : point2), new google.maps.LatLng(point1.lat > point2.lat ? point2 : point1)) + 90;
                    if (heading > 0)
                        heading = heading - 180;
                    if (heading < -70)
                        heading = heading + 180;
                    let newBounds = getBounds(location.bounds!);
                    labelBounds = newBounds.bounds;
                }
                return <React.Fragment key={id}>
                    {
                        !["circle", "marker"].includes(shape) && bounds.length && <>
                            <PolygonComponent
                                onLoad={polygon => polygonRefs.current[id!] = polygon}
                                options={
                                    {
                                        fillOpacity: 1,
                                        fillColor: "#2d892d",
                                        strokeWeight: 2,
                                        strokeColor: "#0f0",
                                        clickable: true,
                                        zIndex: 1,
                                        draggable: false,
                                        geodesic: true,
                                        editable: false
                                    }
                                }
                                key={index}
                                paths={bounds}
                            />

                            <OverlayView
                                bounds={labelBounds}
                                mapPaneName="overlayMouseTarget"
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    style={
                                        {
                                            width: "100%",
                                            height: "100%",
                                            transform: `rotateZ(${heading}deg)`,
                                        }
                                    }
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={
                                            {
                                                width: "100%",
                                                height: "100%",
                                                position: "relative",
                                            }
                                        }
                                    >
                                        <svg
                                            viewBox={`0 0 ${location?.Name?.length! * 6} 18`}
                                            style={
                                                {
                                                    width: "100%",
                                                    height: "100%",
                                                    position: "absolute"
                                                }
                                            }
                                        >
                                            <text fill={theme.palette.getContrastText(fillColor)} style={{fontSize: `${style?.labelFontSize || 10}px`}} x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{location.Name}</text>
                                        </svg>
                                    </Grid>
                                </Grid>
                            </OverlayView>
                        </>
                    }
                    {
                        shape === "circle" && <>
                            <Circle
                                center={JSON.parse(location.circleCenter)}
                                radius={parseFloat(location.circleRadius)}
                                options={{
                                    fillOpacity: 1,
                                    fillColor: "#2d892d",
                                    strokeWeight: 2,
                                    strokeColor: "#0f0",
                                    clickable: true,
                                    zIndex: 1,
                                    draggable: false
                                }}
                                // onClick={() => handleMapLocationClick(circle._id)}
                            />
                            <OverlayView
                                position={JSON.parse(location.circleCenter)}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                            >
                                <div
                                    style={{
                                        background: 'transparent',
                                        padding: '3px',
                                        fontSize: '15px',
                                    }}
                                >
                                    {location.Name}
                                </div>
                            </OverlayView>
                        </>
                    }
                    {
                        shape === "marker" && <Marker
                            key={index}
                            position={JSON.parse(location.bounds)}
                        />
                    }
                </React.Fragment>;
            })
        }
    </GoogleMap>;

    return (
        <div style={{ display: 'flex'}}>
            <div
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: "calc(100vh - 66px)" }}
            >
                <div className={"header-wrapper py-2 px-3"}>
                    <Grid container alignItems={"center"} spacing={1}>
                        <Grid item>
                            <Typography variant={"h6"} className={"pr-2"}>{mapName}</Typography>
                        </Grid>

                        <Grid item>
                            <Typography className={""}>Check In</Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant={"subtitle1"} className={"font-weight-bold"}>{moment(checkInDateTime).tz("UTC").format("MM/DD/YY")}</Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant={"subtitle1"} className={"font-weight-bold pr-2"}>{moment(checkInDateTime).tz("UTC").format("hh:mm A")}</Typography>
                        </Grid>

                        <Grid item>
                            <Typography className={""}>Check Out</Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant={"subtitle1"} className={"font-weight-bold"}>{moment(checkOutDateTime).tz("UTC").format("MM/DD/YY")}</Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant={"subtitle1"} className={"font-weight-bold pr-2"}>{moment(checkOutDateTime).tz("UTC").format("hh:mm A")}</Typography>
                        </Grid>
                    </Grid>
                </div>
                <Grid container spacing={1} style={{ flexGrow: 1 }}>
                    <Grid item xs={5} className={"pt-4 pl-4 pr-2"}>
                        <Grid container className={"px-4 pt-3"}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    className='mb-4'
                                    label={"First Name"}
                                    type="text"
                                    size="small"
                                    value={params.firstName ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        firstName: e.target.value
                                    })}
                                    required
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    className='mb-4'
                                    label={"Last Name"}
                                    type="text"
                                    size="small"
                                    value={params.lastName ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        lastName: e.target.value
                                    })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    className='mb-4'
                                    label={"Zip Code"}
                                    type="text"
                                    size="small"
                                    value={params.zip ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        zip: e.target.value
                                    })}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    className='mb-4'
                                    label={"Mobile Phone"}
                                    type="text"
                                    size="small"
                                    value={params.mobilePhone ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        mobilePhone: e.target.value
                                    })}
                                    required
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    className='mb-4'
                                    label={"Email"}
                                    type="text"
                                    size="small"
                                    value={params.email ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        email: e.target.value
                                    })}
                                    required
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    className={"reserve-btn"}
                                    onClick={() => confirmBooking()}
                                >Confirm Booking</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={7} style={{ width: "100%", height: '100%' }}>

                        <Grid height={"100%"} item xs={12}>
                            {googleMap}
                        </Grid>

                    </Grid>
                </Grid>
            </div>
        </div>
    );
};