import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {$crud} from "./factories/CrudFactory";
import {
    Button,
    Checkbox,
    Dialog,
    DialogProps,
    FormControlLabel,
    Grid,
    IconButton,
    Slide,
    TextField,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import Swal from "sweetalert2";
import {X} from 'react-feather';
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        flexGrow: 1,
    },
    title: { flexGrow: 1 },
    listContainer: {
        maxHeight: 200,
        overflowY: "auto"
    },
    sortablePlaceholder: {
        flex: 1,
        width: 50,
        maxWidth: 50,
        background: "#eee"
    }
}));

function AddUserDialog({ open, onClose, _id, getData }) {
    const [params, setParams] = useState<any>({});
    const history = useHistory();
    const location = useLocation();
    const { classes } = useStyles();

    useEffect(() => {
        if (_id)
            getUserById();
        else
            setParams({});
    }, [_id]);

    const getUserById = async () => {
        try {
            const { data: { user } } = await $crud.get(`users/${_id}`);
            setParams({
                ...user,
                password: "",
                _id: undefined
            });
        } catch (e) {
            console.log(e);
        }
    };

    const save = async () => {
        const warnings: string[] = [];
        if (params.firstName?.trim() === "")
            warnings.push("Please enter First Name.");

        if (params.email?.trim() === "")
            warnings.push("Please enter Email.");

        if (!_id && (params.password?.trim() === ""))
            warnings.push("Please enter Password.");

        if(warnings.length) {
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const {type} = _id ?
                await $crud.post(`users/update/${_id}`, params) :
                await $crud.post("users/create", params);

            if(type === "success")
                history.push("/users");

            onClose();
            getData();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            <Dialog
                maxWidth="xs"
                fullWidth
                open={open}
                TransitionComponent={Slide}
                onClose={onClose}
            >
                <Grid container spacing={1} className={"px-3 pt-2 pb-3"}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <Typography variant="h6" className={classes.title}>
                                    {_id ? 'Update User' : 'Add New User'}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    onClick={onClose}
                                >
                                    <X />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container alignItems={"center"}>
                            <Grid  item xs={12}>
                                <TextField
                                    variant="outlined"
                                    className='mb-4'
                                    label={"First Name"}
                                    type="text"
                                    size="small"
                                    value={params.firstName ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        firstName: e.target.value
                                    })}
                                    required
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    className='mb-4'
                                    label={"Last Name"}
                                    type="text"
                                    size="small"
                                    value={params.lastName ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        lastName: e.target.value
                                    })}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    className='mb-4'
                                    label={"Email or Username"}
                                    type="text"
                                    size="small"
                                    value={params.email ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        email: e.target.value
                                    })}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    className='mb-4'
                                    label={"Password"}
                                    type="password"
                                    size="small"
                                    value={params.password ?? ""}
                                    onChange={(e) => setParams({
                                        ...params,
                                        password: e.target.value
                                    })}
                                    required
                                />
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success mr-3"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={save}
                                >
                                    {_id ? 'Update User' : 'Add New User'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );

}

export default AddUserDialog;
