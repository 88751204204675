import React, {useState} from 'react';
import {Grid, Typography} from "@mui/material";

export default function Steps () {
    const [activeStep, setActiveStep] = useState(1);
    return <div>
        <Typography variant={"h4"} className={"main-heading text-center mb-4"}>A four-step process can reduce yard geofence mapping<br /> from weeks to hours, saving you money.</Typography>
        <Grid container alignItems={"center"} spacing={4}>
            <Grid item xs={6}>
                <img src={"../../images/Flowchart.png"} className={"img-fluid"}/>
            </Grid>

            <Grid item xs={6}>
                <div className={`step-wrapper ${activeStep === 1 && "active"}`} onClick={() => setActiveStep(activeStep === 1 ? 0 : 1)}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <div className={"number"}>1</div>
                        </Grid>

                        <Grid item xs>
                            <h3 className={"heading"}>Login</h3>
                            {
                                activeStep === 1 && <h5 className={"text"}>Login with your Username and Password.</h5>
                            }
                        </Grid>
                    </Grid>
                </div>

                <div className={`step-wrapper ${activeStep === 2 && "active"}`} onClick={() => setActiveStep(activeStep === 2 ? 0 : 2)}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <div className={"number"}>2</div>
                        </Grid>

                        <Grid item xs>
                            <h3 className={"heading"}>Add Map Address</h3>
                            {
                                activeStep === 2 && <h5 className={"text"}>Simply add the address of the warehouse or yard or any location where we want to draw the locations.</h5>
                            }
                        </Grid>
                    </Grid>
                </div>

                <div className={`step-wrapper ${activeStep === 3 && "active"}`} onClick={() => setActiveStep(activeStep === 3 ? 0 : 3)}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <div className={"number"}>3</div>
                        </Grid>

                        <Grid item xs>
                            <h3 className={"heading"}>Draw the Geofence</h3>
                            {
                                activeStep === 3 && <h5 className={"text"}>Utilize the integrated Google Maps interface to zoom, draw, and customize maps with high precision. Users can adjust shapes and boundaries as needed to reflect real-world conditions accurately</h5>
                            }
                        </Grid>
                    </Grid>
                </div>

                <div className={`step-wrapper ${activeStep === 4 && "active"}`} onClick={() => setActiveStep(activeStep === 4 ? 0 : 4)}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <div className={"number"}>4</div>
                        </Grid>

                        <Grid item xs>
                            <h3 className={"heading"}>Export the Locations</h3>
                            {
                                activeStep === 4 && <h5 className={"text"}>Export the geofence as Excel, CSV, or SQL Scripts. We also offer API integration to share geofence data seamlessly</h5>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    </div>;
};
