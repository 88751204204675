import React from 'react';
import {Grid, Typography} from "@mui/material";
import { useHistory } from 'react-router-dom';

export default function WebFooter() {
    return <div className={"web-footer"}>
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <h4>OVERVIEW</h4>
                <a href={""}>Home</a>
                <a href={""}>Pricing</a>
                <a href={""}>View Maps</a>
                <a href={""}>Contact Us</a>
            </Grid>

            <Grid item xs={4}>
                <h4>HELP</h4>
                <a href={""}>Help/FAQ</a>
                <a href={""}>Contact Us</a>
            </Grid>

            <Grid item xs={4}>
                <h4>FEATURES</h4>
                <a href={""}>Search Locations</a>
                <a href={""}>Draw on Maps</a>
                <a href={""}>Analysis</a>
            </Grid>
        </Grid>

        <Grid container justifyContent={"space-between"} alignItems={"center"} spacing={2} className={"mt-5"}>
            <Grid item>
                <a href={"/"}>
                    <Grid container alignItems={"end"} className={"logo"}>
                        <Grid item>
                            <img src={"../../images/logo.png"} className={"img img-fluid"} />
                        </Grid>
                        <Grid item>
                            <div className={"d-flex flex-column"}>
                                <Typography variant={"body1"} className={"title pr-3"}><span>Geofence</span> <span>Mapper</span></Typography>
                                <Typography variant={"caption"} className={"title-slogan"}>Professional Tools for Virtual Geofencing</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </a>
            </Grid>

            <Grid item>
                <Grid container spacing={3} alignItems={"center"}>
                    <Grid item>
                        <a>© 2024 GEOFENCE MAPPER</a>
                    </Grid>

                    <Grid item>
                        <a>Privacy Terms</a>
                    </Grid>

                    <Grid item>
                        <a>Terms and Conditions</a>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
}

