import {CrudRequest, RequestOptions} from "@crud/core";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";

export interface ResponseType<T = any> {
    data: T,
    message: string,
    status_code: "200" | "400" | "401" | string,
    type: string
}

const messages = {
    401: "Invalid username or password",
    404: "Not Found"
};

export class CrudFactory extends CrudRequest {
    baseUrl = "/api/";

    getUrl = (...segments) => segments.reduce((url, segment) => process.env.REACT_APP_API_URL + url + segment, this.baseUrl);

    async get<Request = any, Response = any> (url: string, data: any = {}, requestOptions: RequestInit = {}): Promise<ResponseType<Response>>{
        return this.send({
            method: "GET",
            url,
            data,
            ...requestOptions
        });
    }

    async post<Request = any, Response = any> (url: string, data: any = {}, requestOptions: RequestOptions = {}): Promise<ResponseType<Response>>{
        return this.send({
            method: "POST",
            url,
            data,
            ...requestOptions
        });
    }

    async put<Request = any, Response = any> (url: string, data: any = {}, requestOptions: RequestOptions = {}): Promise<ResponseType<Response>>{
        return this.send({
            method: "PUT",
            url,
            data,
            ...requestOptions
        });
    }

    async delete<Request = any, Response = any> (url: string, data: any = {}, requestOptions: RequestOptions = {}): Promise<ResponseType<Response>>{
        return this.send({
            method: "DELETE",
            url,
            data,
            ...requestOptions
        });
    }

    async send (requestOptions: RequestOptions = {}): Promise<ResponseType<any>>{
        const {url, data, method} = requestOptions;

        const options: RequestInit = {
            ...requestOptions.ajaxOptions,
            method
        };

        let fullUrl;

        options.headers = <any>{
            ...options.headers,
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
            Origin: null

        };

        // @ts-ignore
        options.headers["Authorization"] = `Bearer ${localStorage.getItem("token") || ""}`;

        if (options.method === "GET"){
            const queryString = new URLSearchParams(JSON.parse(JSON.stringify(data)));
            fullUrl = `${this.getUrl(url)}?${queryString}`;
        } else {
            options.body = JSON.stringify(data);
            fullUrl = this.getUrl(url);
        }

        let res: ResponseType = {
            data: [],
            message: "",
            status_code: "",
            type: ""
        };

        try {
            const response = await fetch(fullUrl, options);

            try {
                res = await response.json();
                const {
                    status_code,
                    message = "We're facing some technical issue. Please try again after some time",
                    type
                } = res;

                if(type === "auth-error"){
                    localStorage.clear();
                    window.location.href = "/";
                }

                if(message)
                    if(type === "success")
                        await toast.success(message, {
                            autoClose: 500
                        });

                    else
                        toast.error(message, {
                            autoClose: 500
                        });
                // Swal.fire(<any>{
                //     icon: type,
                //     text: message,
                //     timer: 2000,
                //     timerProgressBar: true
                // });
            } catch (e){
                throw {
                    message: response.statusText ? `${response.status} : ${response.statusText}` : res.message || messages[response.status],
                    status: response.status
                };
            }
        } catch (e){
            console.error(e);

            toast.error(e.message, {
                autoClose: 500
            });

            // Swal.fire(<any>{
            //     icon: "error",
            //     text: e.message,
            //     timer: 2000,
            //     timerProgressBar: true
            // });

            throw e;
        }

        return res;
    }
}

export const $crud = new CrudFactory();
