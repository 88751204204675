import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {$crud} from "./factories/CrudFactory";
import {
    Button,
    Checkbox,
    Dialog,
    DialogProps,
    FormControlLabel,
    Grid,
    IconButton,
    Slide,
    TextField,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import Swal from "sweetalert2";
import {X} from 'react-feather';
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        flexGrow: 1,
    },
    title: { flexGrow: 1 },
    listContainer: {
        maxHeight: 200,
        overflowY: "auto"
    },
    sortablePlaceholder: {
        flex: 1,
        width: 50,
        maxWidth: 50,
        background: "#eee"
    }
}));

function AddLocationDialog({ open, onClose, _id, getData }) {
    const [params, setParams] = useState<any>({});
    const history = useHistory();
    const location = useLocation();
    const { classes } = useStyles();

    useEffect(() => {
        if (_id)
            getLocationById();
        else
            setParams({});
    }, [_id]);

    const getLocationById = async () => {
        try {
            const { data: { location } } = await $crud.get(`locations/${_id}`, {
                attributes: "Name"
            });
            setParams({
                ...location,
                _id: undefined
            });
        } catch (e) {
            console.log(e);
        }
    };

    const save = async () => {
        const warnings: string[] = [];
        if (params.name?.trim() === "")
            warnings.push("Please enter Name.");

        if (warnings.length) {
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const {type} = await $crud.post(`locations/update/${_id}`, params);
            if(type === "success")
                history.push("/locations");

            onClose();
            getData();
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            <Dialog
                maxWidth="xs"
                fullWidth
                open={open}
                TransitionComponent={Slide}
                onClose={onClose}
            >
                <Grid container spacing={1} className={"px-3 pt-2 pb-3"}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <Typography variant="h6" className={classes.title}>
                                    Update Location
                                </Typography>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    onClick={onClose}
                                >
                                    <X />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            className='mb-4'
                            label={"Name"}
                            type='text'
                            size="small"
                            value={params.Name ?? ""}
                            onChange={(e) => setParams({ ...params, Name: e.target.value })}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent={"flex-end"}>
                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success mr-3"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={save}
                                >
                                    Update Location
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );

}

export default AddLocationDialog;
