import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import AddLocationDialog from './AddLocationDialog';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Pagination,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import {tableCellClasses} from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import {debounce} from "lodash";
import {$crud} from "./factories/CrudFactory";
import axios from 'axios';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        alignContent: "center"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    },
    '& td, & th': {
        padding: "0px 15px"
    },
}));

const Locations = () => {
    const [maps, setMaps] = useState<{ _id: string; area: string; }[]>([]);
    const [selectedMap, setSelectedMap] = useState<any>("");
    const [locations, setLocations] = useState<{ _id: string; Name: string; Code: string; }[]>([]);
    const [editLocationId, setEditLocationId] = useState("");
    const rowsPerPage = [25, 50, 75, 100];
    const [openLocationDialog, setOpenLocationDialog] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: rowsPerPage[0]
    });
    const [total, setTotal] = useState({
        pages: 0,
        records: 0
    });

    const [filters, setFilters] = useState({search: ""});
    const [search, setSearch] = useState<string>("");
    let history = useHistory();

    const getMaps = async () => {
        try{
            const {data: {total, maps}} = await $crud.get("maps", {attributes: "_id name area city zip"});
            setMaps(maps);
        }  catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getMaps();
    }, []);

    const exportLocations = async () => {
        const url = `${process.env.REACT_APP_API_URL}/api/locations?addressId=${selectedMap}&search=${search}&is_export=1&token=${localStorage.getItem("token")}`;
        window.open(url);
    }

    const getLocations = async () => {
        if (selectedMap !== "") {
            try {
                const {
                    data: { total, locations },
                } = await $crud.get("locations", {
                    search,
                    addressId: selectedMap,
                    attributes: "_id Name Code",
                    ...pagination
                });
                setLocations(locations);
                setTotal({
                    pages: Math.ceil(total / pagination.limit),
                    records: total,
                });
            } catch (e) {
                console.log(e);
            }
        } else {
            setLocations([]);
            setTotal({
                pages: 0,
                records: 0,
            });
        }
    };

    useEffect(() => {
        getLocations();
    }, [selectedMap, filters, pagination]);

    const setFilter = useCallback((key, value) => {
        setFilters(prev => {
            return {
                ...prev,
                [key]: value
            };
        });
    }, []);

    const debounceSetFilter = useCallback(debounce(setFilter, 800), []);

    useEffect(() => {
        debounceSetFilter(search);
    }, [search]);

    const openBirdEye = async (map) => {
        let address;
        try{
            address = map.name + " " + map.area + " " + map.city + " " + map.zip;
            const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
            await getLatitudeLongitude(address, apiKey);
            history.push("/birdeyeview", {map});
        } catch (e) {
            console.log(e);
        }
    };

    async function getLatitudeLongitude(address, apiKey) {
        try {
            const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: address,
                    key: apiKey
                }
            });
            const data = response.data;
            if (data.status === 'OK') {
                const result = data.results[0];
                const location = result.geometry.location;
                const latitude = location.lat;
                const longitude = location.lng;
                sessionStorage.setItem('longitude', longitude);
                sessionStorage.setItem('latitude', latitude);
                return { latitude, longitude };
            }
            throw new Error('Unable to retrieve latitude and longitude.');
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    const showAlert = (id) => {
        Swal.fire({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this location?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                const confirmButtonText = Swal.getConfirmButton()!.textContent;
                deleteLocation(id);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    };

    const deleteLocation = async (itemId) => {
        try{
            const {type} = await $crud.delete(`locations/${itemId}`);
            if(type === "success")
                getLocations();
        }  catch (e) {
            console.log(e);
        }
    };

    const closeAddLocationDialog = async () => {
        setOpenLocationDialog(false);
    };

    return (
        <div style={{ display: 'flex'}}>
            <AddLocationDialog open={openLocationDialog} onClose={closeAddLocationDialog} _id={editLocationId} getData={getLocations}/>
            <div
                style={{
                    flex: 1,
                    margin: '0 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        // display: 'flex',
                        // justifyContent: 'space-between',
                        // alignItems: 'center',
                        width: '100%',
                        margin: '20px 0',
                    }}
                >
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <h5 style={{ marginRight: 'auto' }}>Locations List</h5>
                        </Grid>

                        <Grid item>
                            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                <InputLabel id="demo-select-small-label">Select Address</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={selectedMap}
                                    label="Select Address"
                                    onChange={(e) => {setSelectedMap(e.target.value)}}
                                    className={"input-bar"}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {
                                        maps.map((map, key) => <MenuItem
                                            value={map._id}
                                        >
                                            {map.area}
                                        </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <Grid container alignItems={"center"}>
                                {
                                    selectedMap && <Grid item>
                                        <Button
                                            className="button mr-2"
                                            onClick={exportLocations}
                                        >
                                            Export To CSV
                                        </Button>
                                    </Grid>
                                }

                                {
                                    selectedMap && <Grid item>
                                        <Button
                                            type="submit"
                                            className="button mr-2"
                                            onClick={() => openBirdEye(maps.find(s => s._id === selectedMap))}
                                        >
                                            Add New Location
                                        </Button>
                                    </Grid>
                                }

                                <Grid item>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={search}
                                        variant="outlined"
                                        placeholder="type for search...."
                                        label="Search By Name"
                                        onChange={e => setSearch(e.target.value)}
                                        className={"input-bar"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <TableContainer component={Paper} className={"table"}>
                    <Table sx={{ minWidth: 700 }} size={"small"} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '30%' }}>
                                    Name
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '30%' }}>
                                    Code
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '30%' }}>
                                    Site ID
                                </StyledTableCell>
                                <StyledTableCell style={{ fontWeight: 'bold', width: '10%' }}>
                                    Action
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                locations.map((location, index) => (
                                    <StyledTableRow>
                                        <StyledTableCell>{location.Name}</StyledTableCell>
                                        <StyledTableCell>{location.Code}</StyledTableCell>
                                        <StyledTableCell>{maps.find(s => s._id === selectedMap)?.area}</StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title="Edit">
                                                <IconButton onClick={() => {
                                                setEditLocationId(location._id);
                                                setOpenLocationDialog(true);
                                            }}  aria-label="edit" style={{ padding: '6px', color: "orange" }}>
                                                    <EditIcon fontSize="medium" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => showAlert(location._id)} aria-label="delete" style={{ padding: '6px', color: "#f00" }}>
                                                    <DeleteIcon fontSize="medium" />
                                                </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))
                            }
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <StyledTableCell style={{padding: "7px"}} colSpan={5}>
                                    <Grid container alignItems={"center"} justifyContent={"right"}>
                                        <Grid item>
                                            <Typography className={"font-weight-bold"} variant={"body1"}>Total : {total.records} | Page:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Pagination
                                                count={total.pages}
                                                color="primary"
                                                onChange={(e, value) => {
                                                    setPagination({...pagination, page: value})
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container justifyContent={"center"} alignItems={"center"}>
                                                <Grid item>
                                                    <Typography className={"font-weight-bold mr-2"} variant={"body2"}>Show</Typography>
                                                </Grid>

                                                <Grid item>
                                                    <select
                                                        style={{fontSize: "16px"}}
                                                        value={String(pagination.limit)} placeholder="" name="limit"
                                                        onChange={
                                                            e => {
                                                                // this.props.onLimitChange(Number(e.target.value));
                                                                // this.changePage(1);
                                                                setPagination({...pagination, limit: Number(e.target.value)})
                                                            }
                                                        }
                                                    >
                                                        {
                                                            rowsPerPage.map((val, key) => <option
                                                                key={key}
                                                                value={val}
                                                            >
                                                                {val}
                                                            </option>)
                                                        }
                                                    </select>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledTableCell>
                            </StyledTableRow >
                        </TableFooter>
                    </Table>
                </TableContainer>

            </div>
        </div>
    );
};

export default Locations;
