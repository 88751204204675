import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {axiosService} from "./services/axiosService";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from 'mdb-react-ui-kit';

export default function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let history = useHistory();

  const handlesignup = async () => {
    // Perform validation
    if (firstName.length === 0) {
      alert('Please enter First Name.');
      return;
    }
    if (lastName.length === 0) {
      alert('Please enter Last Name.');
      return;
    }
    if (!phone.match(/[7-9][0-9]{9}$/)) {
      alert("Please enter valid phone number")
      return false;
    }
    if (!email.match(/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/)) {
      alert('Please enter your email.');
      return;
    }

    if (password.trim() === '') {
      alert('Please enter your password.');
      return;
    }
    const allDataField = { firstname: firstName, lastname: lastName, email: email, phone: phone, password: password }
    await axiosService.post("/registerUser", allDataField, {
      headers: {
        'api-key': process.env.REACT_APP_API_KEY!
      }
    })
      .then(res => {
        alert("New user add successfully.. ");
        history.push("/");
      })
      .catch(error => {
        console.log(error);
      })
  }

  return (
    <MDBContainer fluid style={{ backgroundColor: 'black', height: '100vh' }}>
      <MDBRow className='d-flex justify-content-center align-items-center h-100'>
        <MDBCol col='12'>
          <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
            <MDBCardBody className='p-5 w-100 d-flex flex-column' style={{ height: '100%' }}>
              <h2 className="fw-bold mb-2 text-center">Sign Up</h2>
              {/* <p className="text-white-50 mb-3">Please enter your login and password!</p> */}
              <MDBInput
                wrapperClass='mb-4 w-100'
                placeholder='First Name'
                id='formControlLg'
                type='text'
                size="lg"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <MDBInput
                wrapperClass='mb-4 w-100'
                placeholder='Last Name'
                id='formControlLg'
                type='text'
                size="lg"
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
                required
              />
              <MDBInput
                wrapperClass='mb-4 w-100'
                placeholder='Phone Number'
                id='formControlLg'
                type='number'
                size="lg"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              <MDBInput
                wrapperClass='mb-4 w-100'
                placeholder='Email'
                id='formControlLg'
                type='email'
                size="lg"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <MDBInput
                wrapperClass='mb-4 w-100'
                placeholder='Password'
                id='formControlLg'
                type='password'
                size="lg"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <MDBBtn onClick={handlesignup} style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)', width: '100%', height: '48px' }}>
                Sign Up
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
