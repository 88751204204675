import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBIcon,
    MDBCheckbox
} from 'mdb-react-ui-kit';
import {$crud} from "./factories/CrudFactory";
import Swal from "sweetalert2";
import {Grid, Typography} from "@mui/material";

export default function LogIn() {
    const [params, setParams] = useState<any>({});
    const [isDisabled, setDisabled] = useState(false);
    let history = useHistory();

    if(localStorage.getItem("token"))
        history.push("/maps");

    const login = async () => {
        const warnings: string[] = [];
        if (!params.email || params.email?.trim() === "")
            warnings.push("Please enter Email or Username.");

        if (!params.password || params.password?.trim() === "")
            warnings.push("Please enter Password.");

        if(warnings.length){
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const {data, type} = await $crud.post("login", params);
            if(type === "success"){
                const {token, user} = data;
                await localStorage.setItem("token", token);
                if(data.user?.admin)
                    await localStorage.setItem("admin", "1");
                await localStorage.setItem("token", token);
                await localStorage.setItem("user", JSON.stringify(user));
                history.push(`/maps`);
            }
        } catch (e) {
            console.log("Error", e)
        }
    };

    useEffect(() => {
        setDisabled(params.email?.trim() === '' || params.password?.trim() === '');
    }, [params]);

    return (
        <>
            <div className={"map-bg-img"}></div>
            <MDBContainer fluid style={{height: '100vh'}}>
                <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12'>
                        <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                            <a href={"/"}>
                                <Grid container justifyContent={"center"} alignItems={"end"} className={"logo pt-4"} style={{transform: "scale(1.4)"}}>
                                    <Grid item>
                                        <img src={"../../images/logo.png"} className={"img img-fluid"} />
                                    </Grid>
                                    <Grid item>
                                        <div className={"d-flex flex-column"}>
                                            <Typography variant={"body1"} className={"title pr-3"}><span>Geofence</span> <span>Mapper</span></Typography>
                                            <Typography variant={"caption"} className={"title-slogan"}>Professional Tools for Virtual Geofencing</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </a>
                            <MDBCardBody className='px-5 pb-5 pt-1 w-100 d-flex flex-column' style={{ height: '100%' }}>
                                <h2 className="fw-bold my-4 text-center" style={{ order: -1 }}>Log in</h2>
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    placeholder='Email or Username'
                                    id='formControlLg'
                                    type='email'
                                    size="lg"
                                    value={params.email}
                                    onChange={(e) => setParams({
                                        ...params,
                                        email: e.target.value
                                    })}
                                    required
                                />
                                <MDBInput
                                    wrapperClass='mb-4 w-100'
                                    placeholder='Password'
                                    id='formControlLg'
                                    type='password'
                                    size="lg"
                                    value={params.password}
                                    onChange={(e) => setParams({
                                        ...params,
                                        password: e.target.value
                                    })}
                                    required
                                />
                                <MDBCheckbox name='flexCheck' id='flexCheckDefault' className='mb-4' label='Remember password' />
                                <br/>
                                <MDBBtn disabled = {isDisabled} onClick={login} style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)', width: '100%', height: '48px' }}>
                                    Login
                                </MDBBtn>
                                <p className="mt-3 mb-0">
                                    <a href="">Forgot Password ?</a>
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
}
