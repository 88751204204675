import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    Button,
    Checkbox,
    Dialog,
    DialogProps,
    FormControlLabel,
    Grid,
    IconButton,
    Slide,
    TextField,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Divider
} from '@mui/material';
import { X } from 'react-feather';
import { makeStyles } from "tss-react/mui";
import { $crud } from './factories/CrudFactory';
import Swal from 'sweetalert2';

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        flexGrow: 1,
    },
    title: { flexGrow: 1 },
    listContainer: {
        maxHeight: 200,
        overflowY: "auto"
    },
    sortablePlaceholder: {
        flex: 1,
        width: 50,
        maxWidth: 50,
        background: "#eee"
    }
}));

function AddMapDialog({open, onClose, _id, getData}) {
    const { classes } = useStyles();
    const [params, setParams] = useState<any>({});
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const history = useHistory();

    useEffect(() => {
        if (_id)
            getMapById();
        else
            setParams({});
    }, [_id]);

    const getMapById = async () => {
        try {
            const { data: { map } } = await $crud.get(`maps/${_id}`);
            setParams(map);
        } catch (error) {
            console.error('Error fetching map:', error);
        }
    };

    useEffect(() => {
        axios.get('https://countriesnow.space/api/v0.1/countries')
            .then(response => {
                const countriesNames = response.data.data.map(country => country.country);
                setCountries(countriesNames);
                setParams({
                    ...params,
                    country: "United States"
                })
            })
            .catch(error => {
                console.error('Error fetching countries data:', error);
            });
    }, []);

    useEffect(() => {
        if (params.country) {
            axios.get(`https://countriesnow.space/api/v0.1/countries/states/`, {
                params: { country: params.country }
            })
                .then(response => {
                    const statesList = response.data.data.filter(item => item.name === params.country);
                    if (statesList.length > 0) {
                        const stateNames = statesList[0].states.map(item => item.name);
                        setStates(stateNames);
                    }
                })
                .catch(error => {
                    console.error('Error fetching state data:', error);
                });
        } else {
            setStates([]);
        }
    }, [params]);

    useEffect(() => {
        if (params.country && params.state) {
            axios.post("https://countriesnow.space/api/v0.1/countries/state/cities", {
                country: params.country,
                state: params.state
            })
                .then(response => {
                    const cityNames = response.data.data.map(item => item);
                    setCities(cityNames);
                })
                .catch(error => {
                    console.error('Error fetching city data:', error);
                });
        } else {
            setCities([]);
        }
    }, [params]);

    const save = async () => {

        const warnings: string[] = [];
        if (params.name?.trim() === '')
            warnings.push('Please enter Map Name.');

        if (params.country?.trim() === '')
            warnings.push('Please enter Country.');

        if (params.city?.trim() === '')
            warnings.push('Please enter City.');

        if (params.state?.trim() === '')
            warnings.push('Please enter State.');

        if (params.zip?.trim() === '')
            warnings.push('Please enter Zip Code.');

        if(warnings.length){
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const {type} = _id ?
                await $crud.post(`maps/update/${_id}`, params) :
                await $crud.post("maps/create", params);

            if(type === "success")
                history.push('/maps');

            onClose();
            getData();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div style={{ display: 'flex' }}>
            <Dialog
                maxWidth="md"
                fullWidth
                open={open}
                TransitionComponent={Slide}
                onClose={onClose}
            >
                <Grid container spacing={1} className={"px-3 pt-2 pb-3"}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <Typography variant="h6" className={classes.title}>
                                    {_id ? 'Update Map' : 'Add New Map'}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    onClick={onClose}
                                >
                                    <X />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} className="">
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            className='mb-4'
                                            label={"Street Name"}
                                            type='text'
                                            size="small"
                                            value={params.name ?? ""}
                                            onChange={(e) => setParams({ ...params, name: e.target.value })}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-select-small-label">Select State</InputLabel>
                                            <Select
                                                label="Select State"
                                                className='mb-4'
                                                value={params.state ?? ""}
                                                onChange={(e) => setParams({ ...params, state: e.target.value })}
                                                required
                                            >
                                                <MenuItem value=''>Select State</MenuItem>
                                                {states.map((state, index) => (
                                                    <MenuItem key={index} value={state}>{state}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            className='mb-4'
                                            label={"Area"}
                                            type='text'
                                            size="small"
                                            value={params.area ?? ""}
                                            onChange={(e) => setParams({ ...params, area: e.target.value })}
                                            required
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={12}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-select-small-label">Select a country</InputLabel>
                                            <Select
                                                label="Select a country"
                                                className='mb-4'
                                                value={params.country ?? ""}
                                                onChange={(e) => setParams({ ...params, country: e.target.value })}
                                                required
                                            >
                                                <MenuItem value="">Select a country</MenuItem>
                                                <MenuItem value="United States">United States</MenuItem>
                                                <MenuItem value="Canada">Canada</MenuItem>
                                                <Divider />
                                                {countries.filter(country => !["United States", "Canada"].includes(country)).map((country, index) => (
                                                    <MenuItem key={index} value={country}>{country}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-select-small-label">Select City</InputLabel>
                                            <Select
                                                label="Select City"
                                                className='mb-4'
                                                value={params.city ?? ""}
                                                onChange={(e) => setParams({ ...params, city: e.target.value })}
                                                required
                                            >
                                                <MenuItem value=''>Select City</MenuItem>
                                                {cities.map((city, index) => (
                                                    <MenuItem key={index} value={city}>{city}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            className='mb-4'
                                            label={"ZipCode"}
                                            type='text'
                                            size="small"
                                            value={params.zip ?? ""}
                                            onChange={(e) => setParams({ ...params, zip: e.target.value })}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} justifyContent={"flex-end"}>
                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success mr-3"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>

                            <Grid item className={"p-0"}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    className="btn btn-success"
                                    style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                                    onClick={save}
                                >
                                    {_id ? 'Update Map' : 'Add New Map'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}

export default AddMapDialog;
