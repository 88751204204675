import React, {useEffect, useState} from 'react';
import {Grid, Button, Typography, TextField, Dialog, Slide, IconButton, AppBar, Toolbar} from "@mui/material";
import {Call, Close as CloseIcon} from '@mui/icons-material';
import { useHistory, useLocation } from "react-router-dom";
import {$crud} from "../factories/CrudFactory";
import Swal from "sweetalert2";

export default function WebHeader() {
    const history = useHistory();
    const location = useLocation();

    const [pathName, setPathName] = useState("");
    const [params, setParams] = useState<any>({});
    const [loading, setLoading] = useState<any>(false);
    const [open, setOpen] = useState<any>(false);

    useEffect(() => {
        setPathName(location.pathname.replace(/\//g, ''));
    }, [location.pathname]);

    const login = async () => {
        const warnings: string[] = [];
        if (!params.email || params.email?.trim() === "")
            warnings.push("Please enter Email or Username.");

        if (!params.password || params.password?.trim() === "")
            warnings.push("Please enter Password.");

        if(warnings.length){
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            setLoading(true);
            const {data, type} = await $crud.post("login", params);
            if(type === "success"){
                const {token, user} = data;
                await localStorage.setItem("token", token);
                if(data.user?.admin)
                    await localStorage.setItem("admin", "1");
                await localStorage.setItem("token", token);
                await localStorage.setItem("user", JSON.stringify(user));
                history.push(`/maps`);
            }
            setLoading(false);
        } catch (e) {
            console.log("Error", e)
        }
    };

    return <>
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            TransitionComponent={Slide}
            onClose={() => setOpen(false)}
        >
            <AppBar className={"p-0"} sx={{ position: 'relative' }}>
                <Toolbar className={"pr-2"}>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        Request a Demo of the Geofence Mapper Tool
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => setOpen(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Grid container className={"px-3 pt-1 pb-3"}>
                <Grid item xs={12}>
                    <Typography variant={"subtitle1"} className={"mt-3"}>Discover how the Geofence Mapper Tool can revolutionize your mapping and location management tasks with advanced features like precision shape drawing, center point selection, bulk location management, and more. Our tool, hosted on powerful AWS servers, is designed for seamless performance and reliability.</Typography>
                    <Typography variant={"subtitle1"} className={"mt-3"}>To schedule a personalized demo, simply contact us at:</Typography>
                    <Typography variant={"subtitle1"} className={"mt-3"}>Email: <a className={"font-weight-bold"} href={"mailto:sales@prosperinfotech.com"}>sales@prosperinfotech.com</a></Typography>
                    <Typography variant={"subtitle1"}>Phone: <a className={"font-weight-bold"} href={"tel:+1-940-758-3271"}>940-758-3271</a></Typography>
                    <Typography variant={"subtitle1"} className={"mt-3"}>Our team will walk you through the tool's capabilities, answer any questions, and demonstrate how Geofence Mapper can meet your unique needs. We look forward to helping you optimize your location mapping experience!</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid container justifyContent={"flex-end"} className={"mt-4"}>
                        <Grid>
                            <Button
                                variant={"contained"}
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
        <Grid container alignItems={"center"} justifyContent={"space-between"} className={"web-header py-1 px-3"}>
            <Grid item>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <a href={"/"}>
                            <Grid container alignItems={"end"} className={"logo"}>
                                <Grid item>
                                    <img src={"../../images/logo.png"} className={"img img-fluid"} />
                                </Grid>
                                <Grid item>
                                    <div className={"d-flex flex-column"}>
                                        <Typography variant={"body1"} className={"title pr-3"}><span>Geofence</span> <span>Mapper</span></Typography>
                                        <Typography variant={"caption"} className={"title-slogan"}>Professional Tools for Virtual Geofencing</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </a>
                    </Grid>

                    <Grid item className={"nav-link"}>
                        <Typography variant={"subtitle1"} className={"font-weight-bold"}><a href={""}>Home</a></Typography>
                    </Grid>

                    <Grid item className={"nav-link"}>
                        <Typography variant={"subtitle1"} className={"font-weight-bold"}><a>Pricing</a></Typography>
                    </Grid>

                    <Grid item className={"nav-link"}>
                        <Typography variant={"subtitle1"} className={"font-weight-bold"}><a>Contact</a></Typography>
                    </Grid>

                    <Grid item className={"nav-link"}>
                        <Typography variant={"subtitle1"} className={"font-weight-bold"}><a>Industries</a></Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <Button
                            // disabled={true}
                            onClick={() => setOpen(true)}
                            variant={"outlined"}
                            sx={{
                                color: "white",
                                borderColor: "white",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                    borderColor: "white"
                                },
                            }}
                        >
                            Request Demo
                        </Button>
                    </Grid>

                    <Grid item>
                        <a href="tel:+1-940-758-3271" className={"phone"}>
                            <Call style={{color: "#fff"}} />
                            <span className={"text-light"}>+1.940.758.3271</span>
                        </a>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            className=""
                            label={"Email or Username"}
                            type="text"
                            size="small"
                            value={params.email}
                            onChange={(e) => setParams({
                                ...params,
                                email: e.target.value
                            })}
                            InputProps={{
                                style: {
                                    color: "white",
                                },
                            }}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "white",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            variant="outlined"
                            className=""
                            label={"Password"}
                            type="password"
                            size="small"
                            value={params.password}
                            onChange={(e) => setParams({
                                ...params,
                                password: e.target.value
                            })}
                            InputProps={{
                                style: {
                                    color: "white",
                                },
                            }}
                            InputLabelProps={{
                                style: { color: "white" },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                        borderColor: "white",
                                    },
                                    "&:hover fieldset": {
                                        borderColor: "white",
                                    },
                                    "&.Mui-focused fieldset": {
                                        borderColor: "white",
                                    },
                                },
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <button
                            className={"w-btn primary-bg px-3 py-2"}
                            disabled={loading}
                            onClick={login}
                            style={{fontSize: "14px"}}
                        >LOG IN</button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}

