import * as React from "react";
import {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogProps,
    Grid,
    IconButton,
    Slide,
    TextField,
    Typography,
    Radio, RadioGroup, FormControlLabel, FormControl
} from "@mui/material";
import {X} from "react-feather";
import {LocationType} from "./types";
import {getDistance, getCenterLatLng} from "./helpers";
import {makeStyles} from "tss-react/mui";
import {axiosService} from "./services/axiosService";
import {$crud} from "./factories/CrudFactory";
import { toast } from "react-toastify";

interface AddDockMapDialogInterface extends Partial<DialogProps> {
    bounds: google.maps.LatLngLiteral[],
    onSave: (locations: LocationType[]) => void,
    onCancel?: () => void,
    map: google.maps.Map,
    getLocations: () => void,
    mapId: String,
    selectedShape?: any,
    drawingMode: String,
    setDrawingMode: any
}

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        flexGrow: 1,
    },
    title: {flexGrow: 1},
    listContainer: {
        maxHeight: 200,
        overflowY: "auto"
    },
    sortablePlaceholder: {
        flex: 1,
        width: 50,
        maxWidth: 50,
        background: "#eee"
    }
}));

function interpolate(point1, point2, factor) {
    return {
        lat: point1.lat + (point2.lat - point1.lat) * factor,
        lng: point1.lng + (point2.lng - point1.lng) * factor
    };
}

export const drawDocksOld = (
    bounds: google.maps.LatLngLiteral[],
    locations: LocationType[]
): LocationType[] => {
    const data = bounds.map(({ lat, lng }, index, arr) => {
        const next = arr[index < arr.length - 1 ? index + 1 : 0];
        return {
            distance: getDistance(lat, lng, next.lat, next.lng),
            lat1: lat,
            lng1: lng,
            lat2: next.lat,
            lng2: next.lng,
        };
    });

    data.sort((a, b) => a.distance - b.distance);
    const [first, second] = data;
    const distance = Math.sqrt(
        Math.pow(Math.abs(first.lat1 - second.lat2), 2) +
        Math.pow(Math.abs(first.lng1 - second.lng2), 2)
    );
    const length = distance / locations.length;
    const lat_multiple = first.lat1 > second.lat2 ? -1 : 1;
    const lng_multiple = first.lng2 > second.lng2 ? -1 : 1;

    const updatedLocations = (lng_multiple > 0 ? locations : locations).map(
        (location, i) => {
            const start_percent = ((length * i) / distance) * 100;
            const end_percent = ((length * (i + 1)) / distance) * 100;
            const bounds = new google.maps.LatLngBounds();

            let path: google.maps.LatLngLiteral[] = [];

            path = [
                {
                    lat: first.lat1 + ((Math.abs(first.lat1 - second.lat2) * (start_percent / 100)) * lat_multiple),
                    lng: first.lng1 + ((Math.abs(first.lng1 - second.lng2) * (start_percent / 100)) * lng_multiple)
                },
                {
                    lat: first.lat2 + ((Math.abs(first.lat2 - second.lat1) * (start_percent / 100)) * lat_multiple),
                    lng: first.lng2 + ((Math.abs(first.lng2 - second.lng1) * (start_percent / 100)) * lng_multiple)
                },
                {
                    lat: first.lat2 + ((Math.abs(first.lat2 - second.lat1) * (end_percent / 100)) * lat_multiple),
                    lng: first.lng2 + ((Math.abs(first.lng2 - second.lng1) * (end_percent / 100)) * lng_multiple)
                },
                {
                    lat: first.lat1 + ((Math.abs(first.lat1 - second.lat2) * (end_percent / 100)) * lat_multiple),
                    lng: first.lng1 + ((Math.abs(first.lng1 - second.lng2) * (end_percent / 100)) * lng_multiple)
                }
            ];

            path.forEach((element) => bounds.extend(element));
            return {
                ...location,
                bounds: path,
                center: bounds.getCenter().toJSON(),
            };
        }
    );

    return lng_multiple > 0 ? updatedLocations : updatedLocations
};

export const drawDocks = async (
    bounds: google.maps.LatLngLiteral[],
    locations: LocationType[],
    shape,
    params
) => {
    const sortedByLng = [...bounds].sort((a, b) => a.lng - b.lng);
    const leftEdge = [sortedByLng[0], sortedByLng[1]];
    const rightEdge = [sortedByLng[2], sortedByLng[3]];

    const lngRange = bounds[1].lng - bounds[0].lng;

    const shapesWithCenters: any = [];

    if(params.splitDirection === "vertical") {
        const lngStep = lngRange / params.count;
        for (let i = 0; i < params.count; i++) {
            let corners = [
                { lat: bounds[0].lat, lng: bounds[0].lng + lngStep * i },
                { lat: bounds[3].lat, lng: bounds[3].lng + lngStep * i },
                { lat: bounds[3].lat, lng: bounds[3].lng + lngStep * (i + 1) },
                { lat: bounds[0].lat, lng: bounds[0].lng + lngStep * (i + 1) },
            ];

            let centerLat = (corners[0].lat + corners[2].lat) / 2;
            let centerLng = (corners[0].lng + corners[2].lng) / 2;
            const center = {
                lat: centerLat,
                lng: centerLng
            };

            shapesWithCenters.push({ corners, center });
        }
    }else if (params.splitDirection === "horizontal") {
        for (let i = 0; i < params.count; i++) {
            const leftFactor1 = i / params.count;
            const leftFactor2 = (i + 1) / params.count;

            const left1 = interpolate(leftEdge[0], leftEdge[1], leftFactor1); // Left top of the section
            const right1 = interpolate(rightEdge[0], rightEdge[1], leftFactor1); // Right top of the section
            const left2 = interpolate(leftEdge[0], leftEdge[1], leftFactor2); // Left bottom of the section
            const right2 = interpolate(rightEdge[0], rightEdge[1], leftFactor2); // Right bottom of the section

            const corners = [left1, right1, right2, left2];
            const center = {
                lat: (left1.lat + right1.lat + left2.lat + right2.lat) / 4,
                lng: (left1.lng + right1.lng + left2.lng + right2.lng) / 4
            };

            shapesWithCenters.push({ corners, center });
        }
    }

    return locations.map((location, index) => {
        return {
            ...location,
            bounds: shapesWithCenters[index]?.corners,
            center: shapesWithCenters[index]?.center
        }
    });
};

export function AddMapShapeDialog({open, onClose, map, mapId, onSave, onCancel, bounds, getLocations, selectedShape, drawingMode, setDrawingMode, ...dialogProps}: AddDockMapDialogInterface) {
    const {classes} = useStyles();
    const [loading, setLoading] = useState(false);
    const [defaultShapeName, setDefaultShapeName] = useState("");
    const paramsInitialState = {
        count: 1,
        labelNumberingStartFrom: 1,
        splitDirection: "vertical",
        labelStartFrom: "left"
    };
    const [params, setParams] = useState<any>(paramsInitialState);


    useEffect(() => {
        if(["circle", "marker", "pentagon"].includes(selectedShape)){
            setDefaultShapeName("");
            return;
        }

        if(bounds.length === 4){
            setDefaultShapeName("");
            return;
        }
    }, [selectedShape, bounds]);

    useEffect(() => {
        setParams({
            ...params,
            labelStartFrom: params.splitDirection === "vertical" ? "left" : "top"
        });
    }, [params.splitDirection]);

    const save = async () => {
        let locations1 = {} ;
        try {
            setLoading(true);
            let locations: LocationType[] = [];
            if(params.count === 1){
                locations1 = [
                    {
                        name: defaultShapeName,
                        bounds,
                        center: await getCenterLatLng(bounds),
                        id: 1
                    }
                ];
            } else if(params.count > 1){
                const isReverse = ["right", "top"].includes(params.labelStartFrom);
                for (let i = 0; i < params.count; i++) {
                    const j = isReverse
                        ? (params.labelNumberingStartFrom + params.count - 1 - i)
                        : (params.labelNumberingStartFrom + i);

                    locations.push({
                        id: j,
                        name: defaultShapeName + " " + j,
                        bounds: [],
                    });
                }
            }

            if (drawingMode === 'circle') {
                let reqData = {}
                const savedCircles = sessionStorage.getItem('circles');
                if (savedCircles) {
                    const parsedCircles = JSON.parse(savedCircles)
                    reqData = {
                        circleCenter: JSON.stringify(parsedCircles[parsedCircles.length-1].center),
                        circleRadius: parsedCircles[parsedCircles.length-1].radius,
                        shape : drawingMode,
                        Name : locations1[0].name,
                        addressId: mapId,
                        style: {
                            fontSize: 20
                        }
                    };
                }
                try{
                    await $crud.post("locations/create", reqData);
                    onClose?.({}, "escapeKeyDown");
                }catch (e) {
                    console.log(e);
                } finally {
                    await getLocations();
                }
                return;
            }
            else {
                if(params.count > 1)
                    // locations1 = await drawDocks(bounds, locations, drawingMode, params);
                    locations1 = await drawDocksOld(bounds, locations);
            }
            try{
                if(!(params.count >= 1 && params.count <= 400))
                    toast.warning("Count must be in between 1 to 400");

                else if(!(params.labelNumberingStartFrom >= 1 && params.labelNumberingStartFrom <= 400))
                    toast.warning("Label Numbering must be in between 1 to 400");

                else{
                    await $crud.post("locations/create", {
                        locations: locations1,
                        isGroup : params.count > 1,
                        addressId: mapId,
                        style: {
                            fontSize: 20
                        }
                    });
                    onSave?.(locations);
                    setParams(paramsInitialState);
                    onClose?.({}, "escapeKeyDown");
                }
            }catch (e) {
                console.log(e);
            }
        } finally {
            setLoading(false);
            await getLocations();
            setDrawingMode(undefined);
        }
    };

    const cancel = () => {
        onClose?.({}, "escapeKeyDown");
    };
    return (
        <Dialog
            maxWidth="xs"
            fullWidth
            open={open!}
            scroll="body"
            {...dialogProps}
            TransitionComponent={Slide}
        >
            <Grid container alignItems={"center"} justifyContent={"space-between"} className="py-2 px-3">
                <Grid item>
                    <Typography variant="h6" className={classes.title}>
                        Add Locations
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={cancel}>
                        <X size={25}/>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="column" wrap="nowrap" className="py-2 px-3">
                <Grid item>
                    <TextField
                        fullWidth
                        size="small"
                        label="Name"
                        variant="outlined"
                        type="text"
                        value={defaultShapeName}
                        onChange={e => setDefaultShapeName(e.target.value)}
                    />
                </Grid>
                {
                    bounds.length === 4 && <>
                        <Grid item>
                            <TextField
                                fullWidth
                                size="small"
                                label="Split count"
                                variant="outlined"
                                type="number"
                                value={params.count}
                                onChange={e => {
                                    const value = Number(e.target.value);
                                    setParams({
                                        ...params,
                                        count: value === 0 ? undefined : Math.min(Math.max(value, 1), 400)
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                size="small"
                                label="Label Numbering Start From"
                                variant="outlined"
                                type="number"
                                value={params.labelNumberingStartFrom}
                                onChange={e => {
                                    const value = Number(e.target.value);
                                    setParams({
                                        ...params,
                                        labelNumberingStartFrom: value === 0 ? undefined : Math.min(Math.max(value, 1), 400)
                                    });
                                }}
                            />
                        </Grid>

                        {/*<Grid item>*/}
                        {/*    <Typography className={"font-weight-bold"} variant={"subtitle1"}>Split the shapes into</Typography>*/}
                        {/*    <FormControl>*/}
                        {/*        <RadioGroup*/}
                        {/*            row*/}
                        {/*            defaultValue={"vertical"}*/}
                        {/*            value={params.splitDirection}*/}
                        {/*            onChange={e => setParams({*/}
                        {/*                ...params,*/}
                        {/*                splitDirection: e.target.value*/}
                        {/*            })}*/}
                        {/*        >*/}
                        {/*            <FormControlLabel value="vertical" control={<Radio color={"default"} />} label="Vertical" />*/}
                        {/*            <FormControlLabel value="horizontal" control={<Radio color={"default"} />} label="Horizontal" />*/}
                        {/*        </RadioGroup>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}

                        {/*<Grid item>*/}
                        {/*    <Typography className={"font-weight-bold"} variant={"subtitle1"}>Labeling start from</Typography>*/}
                        {/*    {*/}
                        {/*        params.splitDirection === "vertical" && <FormControl>*/}
                        {/*            <RadioGroup*/}
                        {/*                row*/}
                        {/*                defaultValue={"left"}*/}
                        {/*                value={params.labelStartFrom}*/}
                        {/*                onChange={e => setParams({*/}
                        {/*                    ...params,*/}
                        {/*                    labelStartFrom: e.target.value*/}
                        {/*                })}*/}
                        {/*            >*/}
                        {/*                <FormControlLabel value="left" control={<Radio color={"default"} />} label="Start" />*/}
                        {/*                <FormControlLabel value="right" control={<Radio color={"default"} />} label="End" />*/}
                        {/*            </RadioGroup>*/}
                        {/*        </FormControl>*/}
                        {/*    }*/}

                        {/*    {*/}
                        {/*        params.splitDirection === "horizontal" && <FormControl>*/}
                        {/*            <RadioGroup*/}
                        {/*                row*/}
                        {/*                defaultValue={"top"}*/}
                        {/*                value={params.labelStartFrom}*/}
                        {/*                onChange={e => setParams({*/}
                        {/*                    ...params,*/}
                        {/*                    labelStartFrom: e.target.value*/}
                        {/*                })}*/}
                        {/*            >*/}
                        {/*                <FormControlLabel value="top" control={<Radio color={"default"} />} label="Top" />*/}
                        {/*                <FormControlLabel value="bottom" control={<Radio color={"default"} />} label="Bottom" />*/}
                        {/*            </RadioGroup>*/}
                        {/*        </FormControl>*/}
                        {/*    }*/}
                        {/*</Grid>*/}
                    </>
                }

            </Grid>

            <Grid container justifyContent="flex-end" className="py-2 px-3">
                <Grid item>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn btn-success mr-2"
                        style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        size="small"
                        className="btn btn-success"
                        style={{ backgroundImage: 'linear-gradient(to right, #E160D9, #6841EC)' }}
                        onClick={save}
                        disabled={loading}
                    >
                        {loading ? "Saving" : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
}
