import React, { useState } from 'react';
import {Grid, Typography} from "@mui/material";
import WebHeader from "./components/WebHeader";
import WebFooter from "./components/WebFooter";
import Steps from "./Steps";
import { useHistory } from 'react-router-dom';
import Carousel from "react-bootstrap/Carousel";

export default function Home() {
    const history = useHistory();
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return <>
        <Grid container className={"home"}>
            <Grid item xs={12}>
                <WebHeader />
            </Grid>

            <Grid item xs={12}>
                <Grid container className={"hero-section"} alignItems={"center"}>
                    <Grid xs={8}>
                        <p className={"main-heading"}>Virtual geofencing made <br /> simple with Geofence Mapper!</p>

                        <Grid container className={"mb-2"} alignItems={"center"}>
                            <Grid item>
                                <img src={"../../images/icons/logistics.png"} className={"img-fluid icons mr-2"}/>
                            </Grid>

                            <Grid item xs>
                                <p className={"sub-heading"}>Perfect for Yard Management, Parking Management, Warehousing, and Logistics.</p>
                            </Grid>
                        </Grid>

                        <Grid container className={"mb-2"} alignItems={"center"}>
                            <Grid item>
                                <img src={"../../images/icons/truck.png"} className={"img-fluid icons mr-2"}/>
                            </Grid>

                            <Grid item xs>
                                <p className={"sub-heading"}>Precisely Geofence Entire Yards, Including Parking Spots, Dock Doors, Gates, Buildings,</p>
                            </Grid>
                        </Grid>

                        <Grid container className={"mb-2"} alignItems={"center"}>
                            <Grid item>
                                <img src={"../../images/icons/gps.png"} className={"img-fluid icons mr-2 "}/>
                            </Grid>

                            <Grid item xs>
                                <p className={"sub-heading"}>And More, to Ensure Accurate Trailer Tracking and Streamlined Inventory Management<br /> in Real-Time.</p>
                            </Grid>
                        </Grid>

                        <div className={"buttons"}>
                            <button className={"w-btn big mr-3"} style={{backgroundColor: "#2960ff", borderColor: "#2960ff"}} onClick={() => history.push("/login")}>LOG IN</button>
                            <button className={"w-btn primary-bg big px-2"}>Get Started for Free</button>
                        </div>
                        {/*<button className={"mt-3 ml-4 w-btn primary-bdr big"}>Sign Up</button>*/}
                    </Grid>

                    {/*<Grid item xs={6} className={"carousel"}>*/}
                    {/*    <Carousel*/}
                    {/*        style={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}*/}
                    {/*        data-bs-theme="light"*/}
                    {/*        controls={true}*/}
                    {/*        slide={true}*/}
                    {/*        indicators={true}*/}
                    {/*        fade*/}
                    {/*        activeIndex={index}*/}
                    {/*        onSelect={handleSelect}*/}
                    {/*    >*/}
                    {/*        <Carousel.Item interval={2000}>*/}
                    {/*            <img src={"../../images/carousel/1.png"} className={"img-fluid"}/>*/}
                    {/*        </Carousel.Item>*/}

                    {/*        <Carousel.Item interval={2000}>*/}
                    {/*            <img src={"../../images/carousel/2.png"} className={"img-fluid"}/>*/}
                    {/*        </Carousel.Item>*/}

                    {/*        <Carousel.Item interval={2000}>*/}
                    {/*            <img src={"../../images/carousel/3.png"} className={"img-fluid"}/>*/}
                    {/*        </Carousel.Item>*/}
                    {/*    </Carousel>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>

            <Grid item xs={12} className={"my-5 px-3"}>
                <Steps />
            </Grid>
        </Grid>

        <Grid container>
            <Grid item xs={12}>
                <WebFooter />
            </Grid>
        </Grid>
    </>;
}