import React, { useState } from "react";
import {BirdEyeView} from "./BirdsEye";
import Maps from "./Maps";
import Locations from "./Locations"
import Users from "./Users"
import LogIn from "./LogIn";
import SignUp from "./SignUp";
import PurchaseParking from "./PurchaseParking";
import ParkingCheckout from "./ParkingCheckout";
import ParkingConfirmation from "./ParkingConfirmation";
import Header from "./components/Header";
import Bookings from "./Bookings";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import "./web.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "./Home";
import {ToastContainer} from "react-toastify";

function App() {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    return (
        <Router>
            <div className="App">
                <ToastContainer />
                <Route render={({ location }) => (
                    !["/", "/login", "/sign-up", "/birdeyeview"].includes(location.pathname) && <Header />
                )} />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={LogIn} />
                    <Route exact path="/birdeyeview" component={BirdEyeView} />
                    <Route exact path="/sign-up" component={SignUp} />
                    <Route exact path="/locations" component={Locations} />
                    <Route exact path="/maps" component={Maps} />
                    <Route exact path="/users" component={Users} />
                    <Route exact path="/purchase-parking" component={PurchaseParking} />
                    <Route exact path="/purchase-parking/checkout/:locationId" component={ParkingCheckout} />
                    <Route exact path="/purchase-parking/confirmation/:bookingId" component={ParkingConfirmation} />
                    <Route exact path="/bookings" component={Bookings} />
                </Switch>
                {/* <Footer/> */}
            </div>
        </Router>
    );
}

export default App;
