import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {
    Grid,
    Button,
    Typography,
    TextField
} from "@mui/material";
import Swal from "sweetalert2";
import {$crud} from "./factories/CrudFactory";

export default function Bookings() {
    const history = useHistory();

    const [params, setParams] = useState({
        bookingId: "",
        email: ""
    });

    const submit = async () => {
        const warnings: string[] = [];

        if (params.bookingId?.trim() === "" && params.email?.trim() === "")
            warnings.push('Please enter Booking Id or Email.');

        if(warnings.length){
            await Swal.fire({
                icon: "warning",
                text: warnings[0]
            });
            return;
        }

        try{
            const {type, data: {bookingId}} = await $crud.get("parking/details", params);

            if(type === "success")
                history.push(`/purchase-parking/confirmation/${bookingId}`)
        }catch (e) {
            console.log(e);
        }
    };

    return (
        <div style={{ display: 'flex'}}>
            <div
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: "calc(100vh - 66px)" }}
            >
                <Grid container spacing={2} className={"p-3"}>
                    <Grid item xs={3}>
                        <Typography variant={"h6"}>Search Parking by Booking ID or Email</Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            className='mt-4'
                            label={"Booking ID"}
                            type='text'
                            size="small"
                            value={params.bookingId ?? ""}
                            onChange={(e) => setParams({ ...params, bookingId: e.target.value })}
                            required
                        />

                        <div className={"divider-text mt-4"}>OR</div>

                        <TextField
                            fullWidth
                            variant="outlined"
                            className='mt-4'
                            label={"Email"}
                            type='text'
                            size="small"
                            value={params.email ?? ""}
                            onChange={(e) => setParams({ ...params, email: e.target.value })}
                            required
                        />

                        <Button
                            fullWidth
                            className={"reserve-btn mt-4"}
                            onClick={() => submit()}
                        >Submit</Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};