import React, {useEffect, useState, useMemo, useRef} from "react";
import {useParams} from "react-router-dom";
import {
    Grid,
    Button,
    useTheme,
    Typography
} from "@mui/material";
import {$crud} from "./factories/CrudFactory";
import {Circle, GoogleMap, OverlayView, Polygon as PolygonComponent, Marker ,useLoadScript} from "@react-google-maps/api";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {getBounds, getDistance, getNavigateUrl} from "./helpers";
import {LatLngLiteral} from "@googlemaps/google-maps-services-js";
import moment from "moment-timezone";
export default function ParkingConfirmation() {
    const theme = useTheme();
    const {bookingId} = useParams();
    const [parking, setParking] = useState<any>(null);

    const [gMap, setGMap] = useState<google.maps.Map>();
    const [zoom, setZoom] = useState(20);
    const [mapTypeId, setMapTypeId] = useState<string>(google.maps.MapTypeId.SATELLITE);
    const [locations, setLocations] = useState<any>([]);
    const polygonRefs = useRef<Record<number, google.maps.Polygon>>({});

    const [center, setCenter] = useState({
        lat: 32.940454,
        lng: -96.912492
    });
    const libraries = useMemo<Libraries>(() => ["geometry", "drawing", "places"], []);
    const {isLoaded} = useLoadScript({
        id: "birdview",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
        libraries
    });

    useEffect(() => {
        if (bookingId)
            getParkingByBookingId();
    }, []);

    const getParkingByBookingId = async () => {
        try {
            const { data: {parking} } = await $crud.get(`parking/${bookingId}`);
            setParking(parking);
        } catch (e) {
            console.log(e);
        }
    };

    const openBirdEye = async () => {
        try{
            if(parking?.location?.shape === "circle"){
                setCenter({
                    lat: JSON.parse(parking?.location?.circleCenter)?.lat,
                    lng: JSON.parse(parking?.location?.circleCenter)?.lng
                });
            }else if(parking?.location?.shape === "marker"){
                setCenter({
                    lat: JSON.parse(parking?.location?.bounds)?.lat,
                    lng: JSON.parse(parking?.location?.bounds)?.lng
                });
            } else{
                setCenter({
                    lat: parking?.location.Latitude4,
                    lng: parking?.location.Longitude4
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getLocations = async () => {
        try{
            const {data: {locations}} = await $crud.get("locations", {
                addressId: parking?.address?._id,
                locationId: parking?.locationId
            });
            setLocations(locations);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if(parking){
            openBirdEye();
            getLocations();
        }
    }, [parking]);

    const navigateToLocation = async () => {
        const url = await getNavigateUrl(parking.location);
        window.open(url, '_blank');
    };

    const googleMap = isLoaded && center && <GoogleMap
        onLoad={setGMap}
        id="BirdEyeViewMap"
        center={center}
        zoom={zoom}
        mapTypeId={mapTypeId}
        onMapTypeIdChanged={() => gMap && setMapTypeId(gMap.getMapTypeId()!)}
        onZoomChanged={() => gMap && setZoom(gMap.getZoom()!)}
        mapContainerStyle={
            {
                width: "100%",
                height: "100%"
            }
        }
        clickableIcons={false}
        tilt={0}
        options={{
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeId: "satellite"
        }}
    >
        {
            locations.map((location, index) => {
                const {bounds, id, style, shape} = location;
                let heading, labelBounds;
                const fillColor = "#ffffcc";
                if(!["circle", "marker"].includes(shape)) {
                    // @ts-ignore
                    const [point1, point2] = bounds!.reduce<LatLngLiteral[]>((points, coords, i, array) => {
                        const nextPoint = array[i + 1] ?? array[0];
                        const dis = getDistance(coords.lat, coords.lng, nextPoint.lat, nextPoint.lng);
                        return !points.length || dis > getDistance(points[0].lat, points[0].lng, points[1].lat, points[1].lng) ? [coords, nextPoint] : points;
                    }, []);

                    heading = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(point1.lat > point2.lat ? point1 : point2), new google.maps.LatLng(point1.lat > point2.lat ? point2 : point1)) + 90;
                    if (heading > 0)
                        heading = heading - 180;
                    if (heading < -70)
                        heading = heading + 180;
                    let newBounds = getBounds(location.bounds!);
                    labelBounds = newBounds.bounds;
                }
                return <React.Fragment key={id}>
                    {
                        !["circle", "marker"].includes(shape) && bounds.length && <>
                            <PolygonComponent
                                onLoad={polygon => polygonRefs.current[id!] = polygon}
                                options={
                                    {
                                        fillOpacity: 1,
                                        fillColor: "#2d892d",
                                        strokeWeight: 2,
                                        strokeColor: "#0f0",
                                        clickable: true,
                                        zIndex: 1,
                                        draggable: false,
                                        geodesic: true,
                                        editable: false
                                    }
                                }
                                key={index}
                                paths={bounds}
                            />

                            <OverlayView
                                bounds={labelBounds}
                                mapPaneName="overlayMouseTarget"
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    style={
                                        {
                                            width: "100%",
                                            height: "100%",
                                            transform: `rotateZ(${heading}deg)`,
                                        }
                                    }
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={
                                            {
                                                width: "100%",
                                                height: "100%",
                                                position: "relative",
                                            }
                                        }
                                    >
                                        <svg
                                            viewBox={`0 0 ${location?.Name?.length! * 6} 18`}
                                            style={
                                                {
                                                    width: "100%",
                                                    height: "100%",
                                                    position: "absolute"
                                                }
                                            }
                                        >
                                            <text fill={theme.palette.getContrastText(fillColor)} style={{fontSize: `${style?.labelFontSize || 10}px`}} x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{location.Name}</text>
                                        </svg>
                                    </Grid>
                                </Grid>
                            </OverlayView>
                        </>
                    }
                    {
                        shape === "circle" && <>
                            <Circle
                                center={JSON.parse(location.circleCenter)}
                                radius={parseFloat(location.circleRadius)}
                                options={{
                                    fillOpacity: 1,
                                    fillColor: "#2d892d",
                                    strokeWeight: 2,
                                    strokeColor: "#0f0",
                                    clickable: true,
                                    zIndex: 1,
                                    draggable: false
                                }}
                                // onClick={() => handleMapLocationClick(circle._id)}
                            />
                            <OverlayView
                                position={JSON.parse(location.circleCenter)}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                            >
                                <div
                                    style={{
                                        background: 'transparent',
                                        padding: '3px',
                                        fontSize: '15px',
                                    }}
                                >
                                    {location.Name}
                                </div>
                            </OverlayView>
                        </>
                    }
                    {
                        shape === "marker" && <Marker
                            key={index}
                            position={JSON.parse(location.bounds)}
                        />
                    }
                </React.Fragment>;
            })
        }
    </GoogleMap>;

    return (
        <div style={{ display: 'flex'}}>
            <div
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: "calc(100vh - 66px)" }}
            >
                <Grid container spacing={1} style={{flexGrow: 1}}>
                    <Grid item xs={5} className={"pt-4 pl-4"}>
                        <div className={"parking-info-wrapper"}>
                            {
                                parking && <Grid container spacing={2} justifyContent={"space-between"}>
                                    <Grid item xs={12} className={"box mt-3"}>
                                        <Typography variant={"h6"} className={"title"}>Customer Details</Typography>
                                        <div className={"p-3"}>
                                            <Typography variant={"body1"} className={"font-weight-bold"}>Booking ID</Typography>
                                            <Typography variant={"h6"} className={"font-weight-bold"}>{parking.bookingId}</Typography>

                                            <Grid container justifyContent={"space-between"} className={"mt-3"}>
                                                <Grid item>
                                                    <Typography variant={"body1"} className={"font-weight-bold"}>ARRIVAL</Typography>
                                                    <Typography variant={"body1"} className={"font-weight-bold"}>{moment(parking.checkIn).tz("UTC").format("MMM DD, YYYY | hh:mm a")}</Typography>
                                                    <Button
                                                        className={`btn mt-3`}
                                                    >
                                                        Cancel or Modify Booking
                                                    </Button>
                                                </Grid>

                                                <Grid>
                                                    <Typography variant={"body1"} className={"font-weight-bold"}>RETURN</Typography>
                                                    <Typography variant={"body1"} className={"font-weight-bold"}>{moment(parking.checkOut).tz("UTC").format("MMM D, YYYY | hh:mm a")}</Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} className={"box mt-3"}>
                                        <Typography variant={"h6"} className={"title"}>Parking Location</Typography>
                                        <div className={"p-3"}>
                                            <Typography variant={"body1"} className={"font-weight-bold"}>Booked Parking Spot</Typography>
                                            <Typography variant={"h6"} className={"font-weight-bold"}>{parking.location.Name}</Typography>
                                            <Typography variant={"h6"} className={"font-weight-bold mt-3"}>{parking.address.name}</Typography>
                                            <Typography variant={"body1"}>{parking.address.area}</Typography>
                                            <Typography variant={"body1"}>{parking.address.city}, {parking.address.state} {parking.address.zip}</Typography>
                                            <Button
                                                className={`btn mt-3`}
                                                onClick={() => navigateToLocation()}
                                            >
                                                Direction To Parking Spot {parking.location?.Name ? `- ${parking.location.Name}` : ""}
                                            </Button>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} className={"box mt-3"}>
                                        <Typography variant={"h6"} className={"title"}>Contact Information</Typography>
                                        <div className={"p-3"}>
                                            <Typography variant={"body1"} className={"font-weight-bold"}>{parking.details.firstName} {parking.details.lastName}</Typography>
                                            <Typography variant={"body1"} className={"font-weight-bold"}>{parking.details.email}</Typography>
                                            <Typography variant={"body1"} className={"font-weight-bold"}>{parking.details.mobilePhone}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            }
                        </div>

                        <Typography variant={"body1"} className={"font-weight-bold text-center mt-3 gradient-text"}>Location navigation link has been sent on you email - {parking?.details.email}</Typography>
                    </Grid>

                    <Grid item xs={7} style={{width: "100%", height: '100%'}}>

                        <Grid height={"100%"} item xs={12}>
                            {googleMap}
                        </Grid>

                    </Grid>
                </Grid>
            </div>
        </div>
    );
};