import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Grid, IconButton, Typography, useTheme, Tooltip} from "@mui/material";
import {useHistory, useLocation} from 'react-router-dom';
import {$crud} from "../factories/CrudFactory";
import {
    Circle,
    DrawingManager,
    GoogleMap,
    Marker,
    OverlayView,
    Polygon as PolygonComponent,
    useLoadScript
} from "@react-google-maps/api";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {LatLngLiteral} from "@googlemaps/google-maps-services-js";
import {LocationType} from "../types";
import {getBounds, getDistance, rotateBounds, getCenterLatLng} from "../helpers";
import Swal from 'sweetalert2';
import {AddMapShapeDialog} from "../AddMapShapeDialog";
import axios from 'axios';
import EditLocationPopup from "../EditLocationPopup";
import {Fullscreen, FullscreenExit} from '@mui/icons-material';

export default function GMap(props) {
    const {height, allowEdit = false, mapData = null} = props;
    const theme = useTheme();
    let map;
    map = useLocation()?.state?.map;
    if(mapData)
        map = mapData;

    const [center, setCenter] = useState({
        lat: 32.940454,
        lng: -96.912492
    });

    const [fullScreen, setFullScreen] = useState(false);

    const enterFullscreen = () => {
        const element = document.documentElement;
        element.requestFullscreen();
        setFullScreen(!fullScreen);
    };

    const exitFullscreen = () => {
        document.exitFullscreen();
        setFullScreen(!fullScreen);
    };

    const getLatitudeLongitude = async (map) => {
        try {
            const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";

            const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: map.name + " " + map.area + " " + map.city + " " + map.zip,
                    key: apiKey
                }
            });
            const data = response.data;
            if (data.status === 'OK') {
                const result = data.results[0];
                const location = result.geometry.location;
                const latitude = location.lat;
                const longitude = location.lng;
                setCenter({
                    lat: latitude,
                    lng: longitude
                });
            }
            throw new Error('Unable to retrieve latitude and longitude.');
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    const logout = async () => {
        try{
            const {type} = await $crud.post("logout");
            if(type === "success"){
                await localStorage.clear();
                history.push("/");
            }
        } catch (e) {
            console.log("Error", e)
        }
    };

    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [gMap, setGMap] = useState<google.maps.Map>();
    const [zoom, setZoom] = useState(30);

    useEffect(() => {
        setShowLocationNames(!(zoom <= 16));
    }, [zoom]);

    const [mapTypeId, setMapTypeId] = useState<string>(google.maps.MapTypeId.SATELLITE);
    const [drawingMode, setDrawingMode] = useState<any>();
    const [selectedShape, setSelectedShape] = useState("");
    const libraries = useMemo<Libraries>(() => ["geometry", "drawing", "places"], []);
    const [bounds, setBounds] = useState<google.maps.LatLngLiteral[]>();
    const [openDialog, setOpenDialog] = useState(false);
    const [openLocationPopup, setOpenLocationPopup] = useState(false);
    const [circles, setCircles] = useState<{ center: { lat: number; lng: number }; radius: number }[]>([]);
    const polylineRefs = useRef<Record<number, google.maps.Polyline>>({});
    const polygonRefs = useRef<Record<number, google.maps.Polygon>>({});
    const [locations, setLocations] = useState<any>([]);
    const [unchangedLocations, setUnchangedLocations] = useState<any>([]);
    const [selectedLocation, setSelectedLocation] = useState<any>({});
    const [showLocationNames, setShowLocationNames] = useState(true);

    const [newPolygonPath, setNewPolygonPath] = useState<any>([]);
    const handleGoogleMapClick = (e) => {
        const latLng = e.latLng;
        if (latLng) {
            setNewPolygonPath((prev) => [
                ...prev,
                { lat: latLng.lat(), lng: latLng.lng() },
            ]);
        }
    };

    useEffect(() => {
        if(newPolygonPath.length >= 4 && selectedShape === "polygon"){
            sessionStorage.setItem("shape", "polygon");
            setBounds(newPolygonPath);
            setOpenDialog(true);
        }
    }, [newPolygonPath]);

    const [currentMousePosition, setCurrentMousePosition] = useState<any>(null);
    const handleMouseMove = (e) => {
        if (newPolygonPath.length) {
            const latLng = e.latLng;
            if (latLng) {
                setCurrentMousePosition({ lat: latLng.lat(), lng: latLng.lng() });
            }
        }
    };

    useEffect(() => {
        setNewPolygonPath([]);
    }, [drawingMode]);

    const selectedLocationStyle = useMemo(() => locations.find(l => l.id === selectedLocation?.id), [selectedLocation?.id, locations]);

    useEffect(() => {
        if (!selectedLocation?.id) {
            Object.values(polygonRefs.current).forEach((ref: google.maps.Polygon) => ref.setOptions({editable: false}));
        } else{
            setOpenLocationPopup(true);
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (!allowEdit) {
            Object.values(polygonRefs.current).forEach((ref: google.maps.Polygon) => ref.setOptions({editable: false}));
            setSelectedLocation({});
        }
    }, [allowEdit]);

    const selectLocation = (location: LocationType, showEdit = false) => {
        if (allowEdit) {
            setSelectedLocation(location);
            polylineRefs.current[location.id!]?.setOptions({editable: true});

            if (showEdit) {
                setOpenDialog(true);
            }
        }
    };

    const {isLoaded} = useLoadScript({
        id: "birdview",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
        libraries
    });

    const deleteLocation = async ({deleteGroup = false}) => {
        try{
            const {type} = await $crud.delete(`locations/${selectedLocation?.id}`, {
                deleteGroup
            });

            if(type === "success")
                await getLocations();

            await setStorage({
                key: "undo",
                url: `locations/update/${selectedLocation?.id}`,
                method: "post",
                data: {
                    isGroup: deleteGroup,
                    isDeleted: false
                }
            });

            await setStorage({
                key: "redo",
                url: `locations/update/${selectedLocation?.id}`,
                method: "post",
                data: {
                    isGroup: deleteGroup,
                    isDeleted: true
                }
            });
            setOpenLocationPopup(false);
        }  catch (e) {
            console.log(e);
        }
    }

    const deleteLocationAlert = ({deleteGroup = false}) => {
        Swal.fire({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this location?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                const confirmButtonText = Swal.getConfirmButton()!.textContent;
                deleteLocation({deleteGroup});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    };

    const handlePolygonComplete = (polygon) => {
        if (polygon.getPath().getLength() === 6) {
            sessionStorage.setItem('shape', 'hexagon');
            //   const paths = polygon.getPath().getArray();
            //   const bounds = paths.map((path) => ({
            //     lat: path.lat(),
            //     lng: path.lng(),
            //   }));
            const path = polygon?.getPath().getArray().map(a => a.toJSON());
            const bounds = new google.maps.LatLngBounds();
            path.forEach(c => bounds.extend(c));
            // setBounds(path);
            // setOpenDialog(true);
            // polygon?.setMap(null);
            setBounds(path);
            setOpenDialog(true);
            polygon?.setMap(null);
        }
        if (polygon.getPath().getLength() === 5) {
            sessionStorage.setItem('shape', 'pentagon');
            //   const paths = polygon.getPath().getArray();
            //   const bounds = paths.map((path) => ({
            //     lat: path.lat(),
            //     lng: path.lng(),
            //   }));
            const path = polygon?.getPath().getArray().map(a => a.toJSON());
            const bounds = new google.maps.LatLngBounds();
            path.forEach(c => bounds.extend(c));
            setBounds(path);
            setOpenDialog(true);
            polygon?.setMap(null);
        }
        if (polygon.getPath().getLength() === 4) {
            sessionStorage.setItem('shape', 'rectangle');
            const path = polygon?.getPath().getArray().map(a => a.toJSON());
            const bounds = new google.maps.LatLngBounds();
            path.forEach(c => bounds.extend(c));
            setBounds(path);
            setOpenDialog(true);
            polygon?.setMap(null);
        }

        if (polygon.getPath().getLength() > 6) {
            sessionStorage.setItem('shape', 'hexagon');
            const path = polygon?.getPath().getArray().map(a => a.toJSON());
            const bounds = new google.maps.LatLngBounds();
            path.forEach(c => bounds.extend(c));
            // setBounds(path);
            // setOpenDialog(true);
            // polygon?.setMap(null);
            setBounds(path);
            setOpenDialog(true);
            polygon?.setMap(null);
        }
    };

    // const onPolylineComplete = async (polyline: google.maps.Polyline) => {
    //     if (polyline.getPath().getLength() !== 5 || polyline.getPath().getLength() !== 6 ) {
    //         polyline.setMap(null);
    //         return window.alert("Pentagon or Hexagon must have only 5 or 6 corners");
    //     }
    //
    //     const path = polyline?.getPath().getArray().map(a => a.toJSON());
    //     const bounds = new google.maps.LatLngBounds();
    //     path.forEach(c => bounds.extend(c));
    //     setBounds(path);
    //     setOpenDialog(true);
    //     polyline?.setMap(null);
    // };

    const [markerPositions, setMarkerPositions] = useState<google.maps.LatLngLiteral[] | []>([]);

    const handleMarkerComplete = async (marker: google.maps.Marker) => {
        const position = marker.getPosition()!.toJSON() as google.maps.LatLngLiteral;
        const path: LatLngLiteral[] = [];
        const updatedPositions = [...markerPositions, position];
        let reqData = {
            bounds : JSON.stringify(position),
            shape : 'marker',
            addressId: map?._id
        }
        // setOpenDialog(true);
        setMarkerPositions(updatedPositions);
        marker.setMap(null);
        try{
            await $crud.post("locations/create", reqData);
        }catch (e) {
            console.log(e);
        }finally {
            getLocations();
        }
    };

    const [selectedMarkerId, setSelectedMarkerId] = useState<number | null>(null);

    const handleMarkerClick = (markerId: number, showEdit = false) => {
        sessionStorage.setItem('selectedShape','marker');
        let markerData = locations.filter((item) => item?.shape === 'marker');
        if (allowEdit) {
            setSelectedLocation(markerData[markerId]);
            setSelectedMarkerId(markerId);
            polylineRefs.current[markerId]?.setOptions({ editable: true });
            if (showEdit) {
                setOpenDialog(true);
            }
        }
    };

    const handleSliderChange = (e, value) => {
        setLocations(prev => {
            let uniqueKey = locations.find(item => item._id === selectedLocation?.id)?.groupUniqueKey;
            if (uniqueKey) {
                const arr = locations.filter(item => item.groupUniqueKey === uniqueKey);
                const filteredArray = prev.map(item => {
                    const matchingItem = arr.find(secondItem => secondItem._id === item.id);
                    if (matchingItem) {
                        return {
                            ...item,
                            style: {
                                ...item.style,
                                labelFontSize: value
                            }
                        };
                    } else {
                        return item;
                    }
                });
                return filteredArray;
            }
            else {
                return  prev.map(l => l.id === selectedLocation?.id ? {
                    ...l,
                    style: {
                        ...l.style,
                        labelFontSize: value
                    }
                }:l )
            }

        });
    };

    const onRectComplete = async (polygon: google.maps.Rectangle) => {
        sessionStorage.setItem("shape","rectangle");
        const lat1 = polygon.getBounds()?.getSouthWest().lat();
        const lng1 = polygon.getBounds()?.getSouthWest().lng();
        const lat2 = polygon.getBounds()?.getNorthEast().lat();
        const lng2 = polygon.getBounds()?.getNorthEast().lng();
        const path: LatLngLiteral[] = [
            {
                lat: lat1!,
                lng: lng1!
            },
            {
                lat: lat1!,
                lng: lng2!
            },
            {
                lat: lat2!,
                lng: lng2!
            },
            {
                lat: lat2!,
                lng: lng1!
            }
        ];
        // const bounds = new google.maps.LatLngBounds();
        // path.forEach(c => bounds.extend(c));
        setBounds(path);
        setOpenDialog(true);
        polygon?.setMap(null);
    };

    const handleCircleComplete = (circle) => {
        sessionStorage.setItem('shape', 'circle');
        const center = circle.getCenter().toJSON();
        const radius = circle.getRadius();
        const path: LatLngLiteral[] = [];
        const latBoundaryNorth = center.lat + radius;
        const latBoundarySouth = center.lat - radius;
        const lngBoundaryEast = center.lng + radius;
        const lngBoundaryWest = center.lng - radius;
        // Create a new circle object
        const newCircle = {
            center,
            radius,
            bounds: [
                { lat: latBoundaryNorth, lng: center.lng },
                { lat: latBoundarySouth, lng: center.lng },
                { lat: center.lat, lng: lngBoundaryEast },
                { lat: center.lat, lng: lngBoundaryWest },
            ],
        };

        path.push(center);
        path.push(radius);
        const updatedCircles = [...circles, newCircle];
        setBounds(path);
        setOpenDialog(true);
        // Save the updated circles array to session storage
        sessionStorage.setItem('circles', JSON.stringify(updatedCircles));
        circle?.setMap(null);
    };

    const exportToCsv = () => {
        const columnWidth = 30;
        const addressId = sessionStorage.getItem("addressId");
        const padString = (str, width) => {
            const padding = " ".repeat(Math.max(0, width - (str ? str.length : 0)));
            return (str || "") + padding;
        };
        const rows = [
            [
                "Name",
                // "Description",
                // "Address1",
                // "Address2",
                // "City",
                // "State",
                // "Zip",
                // "Status",
                // "SiteID",
                // "Type",
                "Geofence",
                "Latitude1",
                "Longitude1",
                "Latitude2",
                "Longitude2",
                "Latitude3",
                "Longitude3",
                "Latitude4",
                "Longitude4",
                "Latitude5",
                "Longitude5",
                "Latitude6",
                "Longitude6",
                "Latitude7",
                "Longitude7",
                "Latitude8",
                "Longitude8",
                "Latitude9",
                "Longitude9",
                "Latitude10",
                "Longitude10",
                "Latitude11",
                "Longitude11",
                "Latitude12",
                "Longitude12",
                "Latitude13",
                "Longitude13",
                "Latitude14",
                "Longitude14",
                "Latitude15",
                "Longitude15",
                "Latitude16",
                "Longitude16",
                "Latitude17",
                "Longitude17",
                "Latitude18",
                "Longitude18",
                "Latitude19",
                "Longitude19",
                "Latitude20",
                "Longitude20",
                "Circle Radius",
                "Circle Center",
            ],
            ...locations.filter(item => !["circle", "marker"].includes(item.shape)).map((location) => {
                const data = locations.find((l) => l._id === location.id);
                const path = polygonRefs.current[location.id!]?.getPath()?.getArray().map((coords) => coords.toJSON()) ?? [];
                const bounds = new google.maps.LatLngBounds();
                path.forEach((coord) => bounds.extend(coord));
                let geofenceValue = `"${path[0]?.lat} ${path[0]?.lng}`;
                const center = getCenterLatLng(path);

                if (path[1]?.lat !== undefined && path[1]?.lng !== undefined)
                    geofenceValue += `, ${path[1]?.lat} ${path[1]?.lng}`;

                if (path[2]?.lat !== undefined && path[2]?.lng !== undefined)
                    geofenceValue += `, ${path[2]?.lat} ${path[2]?.lng}`;

                if (path[3]?.lat !== undefined && path[3]?.lng !== undefined)
                    geofenceValue += `, ${path[3]?.lat} ${path[3]?.lng}`;

                if (path[4]?.lat !== undefined && path[4]?.lng !== undefined)
                    geofenceValue += `, ${path[4]?.lat} ${path[4]?.lng}`;

                if (path[5]?.lat !== undefined && path[5]?.lng !== undefined)
                    geofenceValue += `, ${path[5]?.lat} ${path[5]?.lng}`;

                if (path[6]?.lat !== undefined && path[6]?.lng !== undefined)
                    geofenceValue += `, ${path[6]?.lat} ${path[6]?.lng}`;

                if (path[7]?.lat !== undefined && path[7]?.lng !== undefined)
                    geofenceValue += `, ${path[7]?.lat} ${path[7]?.lng}`;

                if (path[8]?.lat !== undefined && path[8]?.lng !== undefined)
                    geofenceValue += `, ${path[8]?.lat} ${path[8]?.lng}`;

                if (path[9]?.lat !== undefined && path[9]?.lng !== undefined)
                    geofenceValue += `, ${path[9]?.lat} ${path[9]?.lng}`;

                if (path[10]?.lat !== undefined && path[10]?.lng !== undefined)
                    geofenceValue += `, ${path[10]?.lat} ${path[10]?.lng}`;

                if (path[11]?.lat !== undefined && path[11]?.lng !== undefined)
                    geofenceValue += `, ${path[11]?.lat} ${path[11]?.lng}`;

                if (path[12]?.lat !== undefined && path[12]?.lng !== undefined)
                    geofenceValue += `, ${path[12]?.lat} ${path[12]?.lng}`;

                if (path[13]?.lat !== undefined && path[13]?.lng !== undefined)
                    geofenceValue += `, ${path[13]?.lat} ${path[13]?.lng}`;

                if (path[14]?.lat !== undefined && path[14]?.lng !== undefined)
                    geofenceValue += `, ${path[14]?.lat} ${path[14]?.lng}`;

                if (path[15]?.lat !== undefined && path[15]?.lng !== undefined)
                    geofenceValue += `, ${path[15]?.lat} ${path[15]?.lng}`;

                if (path[16]?.lat !== undefined && path[16]?.lng !== undefined)
                    geofenceValue += `, ${path[16]?.lat} ${path[16]?.lng}`;

                if (path[17]?.lat !== undefined && path[17]?.lng !== undefined)
                    geofenceValue += `, ${path[17]?.lat} ${path[17]?.lng}`;

                if (path[18]?.lat !== undefined && path[18]?.lng !== undefined)
                    geofenceValue += `, ${path[18]?.lat} ${path[18]?.lng}`;

                if (path[19]?.lat !== undefined && path[19]?.lng !== undefined)
                    geofenceValue += `, ${path[19]?.lat} ${path[19]?.lng}`;

                if (path[20]?.lat !== undefined && path[20]?.lng !== undefined)
                    geofenceValue += `, ${path[20]?.lat} ${path[20]?.lng}`;

                geofenceValue += `, ${path[0]?.lat} ${path[0]?.lng}"`;

                return [
                    padString(data?.Name, columnWidth),
                    // padString(data?.Description, columnWidth),
                    // padString(data?.Address1, columnWidth),
                    // padString(data?.Address2, columnWidth),
                    // padString(data?.City, columnWidth),
                    // padString(data?.State, columnWidth),
                    // padString(data?.Zip, columnWidth),
                    // padString(data?.Status, columnWidth),
                    // padString(data?.SiteID, columnWidth),
                    // padString(data?.Type, columnWidth),
                    padString(geofenceValue, 100),
                    padString(path[0]?.lat?.toString(), columnWidth),
                    padString(path[0]?.lng?.toString(), columnWidth),
                    padString(path[1]?.lat?.toString(), columnWidth),
                    padString(path[1]?.lng?.toString(), columnWidth),
                    padString(path[2]?.lat?.toString(), columnWidth),
                    padString(path[2]?.lng?.toString(), columnWidth),
                    padString(path[3]?.lat?.toString(), columnWidth),
                    padString(path[3]?.lng?.toString(), columnWidth),
                    padString(location.fifthPoint ? center?.lat : path[4]?.lat?.toString(), columnWidth),
                    padString(location.fifthPoint ? center?.lng : path[4]?.lng?.toString(), columnWidth),
                    padString(path[5]?.lat?.toString(), columnWidth),
                    padString(path[5]?.lng?.toString(), columnWidth),
                    padString(path[6]?.lat?.toString(), columnWidth),
                    padString(path[6]?.lng?.toString(), columnWidth),
                    padString(path[7]?.lat?.toString(), columnWidth),
                    padString(path[7]?.lng?.toString(), columnWidth),
                    padString(path[8]?.lat?.toString(), columnWidth),
                    padString(path[8]?.lng?.toString(), columnWidth),
                    padString(path[9]?.lat?.toString(), columnWidth),
                    padString(path[9]?.lng?.toString(), columnWidth),
                    padString(path[10]?.lat?.toString(), columnWidth),
                    padString(path[10]?.lng?.toString(), columnWidth),
                    padString(path[11]?.lat?.toString(), columnWidth),
                    padString(path[11]?.lng?.toString(), columnWidth),
                    padString(path[12]?.lat?.toString(), columnWidth),
                    padString(path[12]?.lng?.toString(), columnWidth),
                    padString(path[13]?.lat?.toString(), columnWidth),
                    padString(path[13]?.lng?.toString(), columnWidth),
                    padString(path[14]?.lat?.toString(), columnWidth),
                    padString(path[14]?.lng?.toString(), columnWidth),
                    padString(path[15]?.lat?.toString(), columnWidth),
                    padString(path[15]?.lng?.toString(), columnWidth),
                    padString(path[16]?.lat?.toString(), columnWidth),
                    padString(path[16]?.lng?.toString(), columnWidth),
                    padString(path[17]?.lat?.toString(), columnWidth),
                    padString(path[17]?.lng?.toString(), columnWidth),
                    padString(path[18]?.lat?.toString(), columnWidth),
                    padString(path[18]?.lng?.toString(), columnWidth),
                    padString(path[19]?.lat?.toString(), columnWidth),
                    padString(path[19]?.lng?.toString(), columnWidth),
                    padString("", 60),
                    padString("", 100),
                ];
            }),
        ];

        let circles1 = locations.filter(item => item.shape === "circle");
        circles1.forEach((circle) => {
            const center = JSON.parse(circle.circleCenter);
            const circleCenter = `${center?.lat}, ${center?.lng}`; // Add a comma between lat and lng
            const radius = circle.circleRadius;
            const geofenceValue = `${circleCenter}, ${circle.circleRadius}`; // Add a comma between center and radius
            rows.push([
                padString(circle?.Name, columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString("", columnWidth),
                padString(radius.toString(), 60),
                padString(circleCenter, 100),
                padString(geofenceValue, 100),
            ]);
        });
        const csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    };

    const setStorage = ({key, url, method, data}) => localStorage.setItem(key, JSON.stringify({
        url,
        method,
        data
    }));

    const callUpdateLocations = async (oldData, newData) => {
        try {
            await $crud.post("locations/update", newData);

            await setStorage({
                key: "undo",
                url: "locations/update",
                method: "post",
                data: oldData
            });

            await setStorage({
                key: "redo",
                url: "locations/update",
                method: "post",
                data: newData
            });
        } catch (e) {
            console.log(e);
        } finally {
            await getLocations();
        }
    };

    const getLocations = async () => {
        try{
            const {data: {locations}} = await $crud.get("locations", {addressId: map._id});
            setLocations(locations);
            setUnchangedLocations(locations);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if(map){
            getLatitudeLongitude(map);
            getLocations();
        }
    }, [map]);

    const updateLocation = async () => {
        let bounds;
        try {
            setLoading(true);

            const location = locations.find(s => s.id === selectedLocation?.id);

            if(!["circle", "marker"].includes(location.shape)){
                bounds = polygonRefs.current[location.id!]?.getPath()?.getArray()?.map(coords => coords.toJSON()) ?? location.bounds ?? [];
                bounds?.forEach(coord => new google.maps.LatLngBounds().extend(coord));
            }

            const locationsData = location.groupUniqueKey ?
                locations.filter(s => s.groupUniqueKey === location.groupUniqueKey).map(location => {
                    let bounds;
                    if(!["circle", "marker"].includes(location.shape)){
                        bounds = polygonRefs.current[location.id!]?.getPath()?.getArray()?.map(coords => coords.toJSON()) ?? location.bounds ?? [];
                        bounds?.forEach(coord => new google.maps.LatLngBounds().extend(coord));
                    }
                    return {
                        ...location,
                        ...(!["circle", "marker"].includes(location.shape) ? {bounds: bounds} : {})
                    }
                }) :
                [
                    {
                        id: location.id,
                        Name: location.Name,
                        ...location,
                        ...(!["circle", "marker"].includes(location.shape) ? {bounds: bounds} : {})
                    }
                ];

            await callUpdateLocations(
                {
                    locations: unchangedLocations.filter(s => locationsData.map(s => s.id).includes(s.id))
                },
                {
                    locations: locationsData
                });

            setDrawingMode(undefined);
            setOpenLocationPopup(false);
            setSelectedLocation({});
        } finally {
            setLoading(false);
        }
    };

    const googleMap = isLoaded && center && <GoogleMap
        onLoad={setGMap}
        id="BirdEyeViewMap"
        center={center}
        onClick={handleGoogleMapClick}
        onMouseMove={handleMouseMove}
        zoom={zoom}
        mapTypeId={mapTypeId}
        onMapTypeIdChanged={() => gMap && setMapTypeId(gMap.getMapTypeId()!)}
        onZoomChanged={() => gMap && setZoom(gMap.getZoom()!)}
        onDragEnd={() => gMap && setCenter(gMap.getCenter()?.toJSON()!)}
        options={{
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeId: "satellite",
            draggableCursor: drawingMode === "polygon" ? "crosshair" : ""
        }}
        mapContainerStyle={
            {
                width: "100%",
                height: "100%"
            }
        }
        clickableIcons={false}
        tilt={0}
    >
        {
            drawingMode === "polygon" && newPolygonPath.length && <PolygonComponent
                onMouseMove={handleMouseMove}
                paths={[...newPolygonPath, currentMousePosition]}
                onClick={handleGoogleMapClick}
                onDblClick={() => {
                    sessionStorage.setItem('shape', 'polygon');
                    setBounds(newPolygonPath);
                    setOpenDialog(true);
                }}
                options={{
                    fillColor: "rgba(24,17,238,0.61)",
                    strokeColor: "#000000",
                    strokeWeight: 1.3,
                    fillOpacity: 0.5,
                }}
            />
        }
        {
            drawingMode !== "polygon" && <DrawingManager
                drawingMode={drawingMode}
                onLoad={
                    manager => {
                        manager.addListener("drawingmode_changed", () => {
                            if(manager.getDrawingMode()! !== "polygon")
                                setNewPolygonPath([]);
                            setDrawingMode(manager.getDrawingMode()!);
                        });
                    }
                }
                options={
                    {
                        drawingControl: false,
                        drawingControlOptions: {drawingModes: [google.maps.drawing.OverlayType.POLYGON, google.maps.drawing.OverlayType.RECTANGLE, google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.MARKER,google.maps.drawing.OverlayType.POLYLINE]},
                        rectangleOptions: {
                            strokeWeight: .3,
                            fillOpacity: .5
                        }
                    }
                }
                onPolygonComplete={handlePolygonComplete}
                onRectangleComplete={onRectComplete}
                // onPolylineComplete={onPolylineComplete}
                onCircleComplete={handleCircleComplete}
                onMarkerComplete={handleMarkerComplete}
            />
        }

        {
            locations.map((location, index) => {
                const {bounds, id, style, shape} = location;
                const fillColor = "#ffffcc";
                let point1, point2, heading, labelBounds;
                if(!["circle", "marker"].includes(shape)){
                    // @ts-ignore
                    [point1, point2] = bounds!.reduce<LatLngLiteral[]>((points, coords, i, array) => {
                        const nextPoint = array[i + 1] ?? array[0];
                        const dis = getDistance(coords?.lat, coords.lng, nextPoint?.lat, nextPoint.lng);
                        return !points.length || dis > getDistance(points[0]?.lat, points[0].lng, points[1]?.lat, points[1].lng) ? [coords, nextPoint] : points;
                    }, []);

                    heading = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(point1?.lat > point2?.lat ? point1 : point2), new google.maps.LatLng(point1?.lat > point2?.lat ? point2 : point1)) + 90;
                    if (heading > 0)
                        heading = heading - 180;
                    if (heading < -70)
                        heading = heading + 180;

                    let newBounds = getBounds(location.bounds!);
                    labelBounds = newBounds.bounds;
                }

                const isSelected = selectedLocation?.id === id;

                return <React.Fragment key={id}>
                    {
                        !["circle", "marker"].includes(shape) && bounds.length && <>
                            <PolygonComponent
                                onLoad={polygon => polygonRefs.current[id!] = polygon}

                                onDragEnd={
                                    async (event) => {
                                        let bounds = polygonRefs.current[location.id!]?.getPath()?.getArray()?.map(coords => coords.toJSON()) ?? location.bounds ?? [];
                                        bounds?.forEach(coord => new google.maps.LatLngBounds().extend(coord));
                                        await callUpdateLocations({
                                                locations: [
                                                    {
                                                        id,
                                                        bounds: locations.find(s => s.id === id)?.bounds
                                                    }
                                                ]
                                            },
                                            {
                                                locations: [
                                                    {
                                                        id,
                                                        bounds
                                                    }
                                                ]
                                            });
                                    }
                                }
                                onMouseDown={
                                    () => {
                                        Object.entries(polygonRefs.current).forEach(([container_id, ref]) => {
                                            if (container_id !== id)
                                                ref.setOptions({editable: allowEdit && isSelected});
                                        });
                                    }
                                }
                                onClick={() => selectLocation(location)}
                                // onDblClick={() => selectLocation(location, true)}
                                options={
                                    {
                                        strokeWeight: isSelected ? 1 : 0.5,
                                        strokeColor: isSelected ? "#f00" : "#000",
                                        fillColor,
                                        fillOpacity: 1,
                                        clickable: true,
                                        // zIndex: [BUILDING_TYPE, CONTAINER_TYPE].includes(type) ? 1 : 2,
                                        draggable: allowEdit,
                                        geodesic: true,
                                        editable: allowEdit && isSelected
                                    }
                                }
                                key={index}
                                paths={bounds}
                            />
                            {
                                showLocationNames && <OverlayView
                                    bounds={labelBounds}
                                    mapPaneName="overlayMouseTarget"
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={
                                            {
                                                width: "100%",
                                                height: "100%",
                                                transform: `rotateZ(${heading}deg)`,
                                            }
                                        }
                                    >
                                        <Grid
                                            container
                                            alignItems="center"
                                            justifyContent="center"
                                            style={
                                                {
                                                    width: "100%",
                                                    height: "100%",
                                                    position: "relative",
                                                    fontSize: (style?.fontSize - ((20 - zoom) * 10)) + "px",
                                                    overflow: "hidden"
                                                }
                                            }
                                        >
                                            {location.Name}
                                        </Grid>
                                    </Grid>
                                </OverlayView>
                            }
                        </>
                    }
                    {
                        shape === "circle" && <>
                            <Circle
                                center={JSON.parse(location.circleCenter)}
                                radius={parseFloat(location.circleRadius)}
                                options={{
                                    fillColor: '#ffffcc',
                                    fillOpacity: 1,
                                    strokeWeight: isSelected ? 1 : 0.5,
                                    strokeColor: isSelected ? "#f00" : "#000",
                                    clickable: true,
                                    zIndex: 1,
                                    draggable: allowEdit
                                }}
                                onClick={() => selectLocation(location)}
                                onDragEnd={async (event) => {
                                    const newCenter = event.latLng!.toJSON() as LatLngLiteral;
                                    await callUpdateLocations({
                                            circleCenter: location.circleCenter,
                                            id : location.id
                                        },
                                        {
                                            circleCenter: JSON.stringify(newCenter),
                                            id : location.id
                                        });
                                }}
                            />
                            {
                                showLocationNames && <OverlayView
                                    position={JSON.parse(location.circleCenter)}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    getPixelPositionOffset={(width, height) => ({
                                        x: -(width / 2),
                                        y: -(height / 2),
                                    })}
                                >
                                    <div
                                        style={{
                                            width: "max-content",
                                            backgroundColor: "#ffffcc !important",
                                            background: 'transparent',
                                            fontSize: (style?.fontSize - ((20 - zoom) * 10)) + "px",
                                            transform: "translate(-50%, -50%)"
                                        }}
                                    >
                                        {location.Name}
                                    </div>
                                </OverlayView>
                            }
                        </>
                    }
                    {
                        shape === "marker" && <Marker
                            key={index}
                            position={JSON.parse(location.bounds)}
                            onClick={() => selectLocation(location)}
                        />
                    }
                    {/* <Polyline
                        onLoad={polyline => polylineRefs.current[id!] = polyline}
                        onDrag={
                            () => {
                                setLocations(locations => locations.map(container => {
                                    const path = polylineRefs.current[container.id!]?.getPath()?.getArray()?.map(coords => coords.toJSON()) ?? container.bounds ?? [];
                                    const bounds = new google.maps.LatLngBounds();
                                    path?.forEach(coord => bounds.extend(coord));
                                    return {
                                        ...container,
                                        bounds: path,
                                        coords: path ? bounds?.getCenter()?.toJSON() : undefined
                                    };
                                }));
                            }
                        }
                        onMouseDown={
                            () => {
                                Object.entries(polylineRefs.current).forEach(([container_id, ref]) => {
                                    if (Number(container_id) !== id)
                                        ref.setOptions({editable: false});
                                });
                            }
                        }
                        onClick={() => selectLocation(location)}
                        onDblClick={() => selectLocation(location, true)}
                        options={
                            {
                                strokeWeight: isSelected ? 1 : 0.5,
                                strokeColor: isSelected ? "#f00" : "#000",
                                clickable: true,
                                // zIndex: [BUILDING_TYPE, CONTAINER_TYPE].includes(type) ? 1 : 2,
                                draggable: allowEdit,
                                geodesic: true,
                            }
                        }
                        key={index}
                        path={bounds}
                    /> */}
                </React.Fragment>;
            })
        }
    </GoogleMap>;

    const undoRedoApiCall = async (type) => {
        const storedData = localStorage.getItem(type);
        if (storedData) {
            const localData = JSON.parse(storedData);
            const { method, url, data } = localData || {};

            if (method && url && data) {
                await $crud[method](url, data);
                await getLocations();
            }
        }
    };

    const rotateLocation = async (degree) => {
        if(selectedLocation){
            const {id, bounds} = locations.find(s => s.id === selectedLocation.id);
            const rotatedBounds = await rotateBounds(bounds, degree);

            await callUpdateLocations({
                    locations: [
                        {
                            id,
                            bounds
                        }
                    ]
                },
                {
                    locations: [
                        {
                            id,
                            bounds: rotatedBounds
                        }
                    ]
                });
        }
    };

    const reverse = async () => {
        if(selectedLocation && selectedLocation?.groupUniqueKey){
            const locations = unchangedLocations.filter(s => s.groupUniqueKey === selectedLocation?.groupUniqueKey);

            const reversedNames = [...locations].reverse().map((location, index) => ({
                ...location,
                Name: locations[index].Name
            })).reverse();

            await callUpdateLocations({
                    locations
                },
                {
                    locations: reversedNames
                });

            setOpenLocationPopup(false);
        }
    };

    return <>
        <EditLocationPopup
            open={openLocationPopup}
            locationId={selectedLocation?.id}
            locations={locations}
            setLocations={setLocations}
            onClose={() => {
                setOpenLocationPopup(false);
                setSelectedLocation({});
            }}
            update={updateLocation}
            deleteLocation={deleteLocationAlert}
            reverse={reverse}
        />
        {
            gMap && bounds &&
            <AddMapShapeDialog
                open={openDialog}
                bounds={bounds}
                map={gMap}
                mapId={map?._id}
                onSave={() => getLocations()}
                onClose={() => {
                    setOpenDialog(false);
                    setNewPolygonPath([]);
                }}
                getLocations={getLocations}
                selectedShape={selectedShape}
                drawingMode={drawingMode}
                setDrawingMode={setDrawingMode}
            />
        }
        <Grid height={height} item xs container>
            <Grid height={height}></Grid>
            <Grid item xs container className={"position-relative"}>
                {
                    allowEdit && <Grid container alignItems="center" className="map-bottom-strip py-2" >
                        <Grid item xs>
                            <Grid container alignItems={"center"}>
                                <Grid item>
                                    <Button
                                        size={"small"}
                                        className="button mr-2"
                                        onClick={() => history.push("/maps")}
                                    >
                                        Back To Dashboard
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Typography variant="h6" className={"mr-2"}>Map</Typography>
                                </Grid>

                                <Grid item xs>
                                    <Typography variant={"body1"}>{map?.area ? `${map.area},` : ""} {map?.city ? `${map.city},` : ""} {map?.state ? `${map.state},` : ""} {map?.zip ? `${map.zip}`: ""}</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid style={{display:'flex'}}>
                            <Grid>
                                {
                                    locations.length > 0 ? <>
                                        <Button
                                            size={"small"}
                                            className="button mr-2"
                                            onClick={exportToCsv}
                                        >
                                            Export As CSV
                                        </Button>

                                        <Button
                                            size={"small"}
                                            className="mr-2"
                                            disabled={true}
                                            variant={"contained"}
                                            // onClick={exportToCsv}
                                        >
                                            Export As SQL Script
                                        </Button>
                                    </>:<></>
                                }
                            </Grid>

                            <Grid>
                                <Button
                                    size={"small"}
                                    className="button mr-2"
                                    onClick={logout}
                                >
                                    Log Out
                                </Button>
                            </Grid>
                        </Grid>
                        {/*{*/}
                        {/*    selectedLocation?.id &&*/}
                        {/*    <Grid container item xs={6} alignItems="center" justifyContent="flex-end" className="p-2-all p-0 w-auto">*/}
                        {/*        <Typography>Font Scaling: </Typography>*/}
                        {/*        <Grid item xs>*/}
                        {/*            <Slider*/}
                        {/*                min={0}*/}
                        {/*                max={20}*/}
                        {/*                value={selectedLocationStyle?.style?.labelFontSize || 10}*/}
                        {/*                onChange={handleSliderChange}*/}
                        {/*            />*/}
                        {/*        </Grid>*/}
                        {/*        <Grid item>*/}
                        {/*            <Button*/}
                        {/*                onClick={showAlert}*/}
                        {/*                className="button mr-2"*/}
                        {/*            >*/}
                        {/*                Delete*/}
                        {/*            </Button>*/}
                        {/*        </Grid>*/}
                        {/*    </Grid>*/}
                        {/*}*/}
                    </Grid>
                }
                {
                    allowEdit && <div className={"map-icons-wrapper"}>

                        <div className={"icons"}>
                            <Tooltip title="Rotate Left">
                                <div className={`icon ${openLocationPopup && "active"}`} onClick={() => {rotateLocation(1)}}>
                                    <img src={"../../images/map-icons/rotate-left.svg"} className={"rotate-svg"} />
                                </div>
                            </Tooltip>

                            <Tooltip title="Undo">
                                <div className={"icon"} onClick={() => {undoRedoApiCall("undo")}}>
                                    <img src={"../../images/map-icons/undo.svg"} />
                                </div>
                            </Tooltip>

                            <Tooltip title="Move the Map">
                                <div className={`icon ${drawingMode === undefined && "active"}`} onClick={() => {
                                    setDrawingMode(undefined);
                                    setSelectedShape("");
                                }}>
                                    <img src={"../../images/map-icons/hand.webp"} className={"hand small"} />
                                </div>
                            </Tooltip>

                            <Tooltip title="Draw a Polygon">
                                <div className={`icon ${drawingMode === "polygon" && !["pentagon"].includes(selectedShape) && "active"}`} onClick={() => {
                                    setDrawingMode("polygon");
                                    setSelectedShape("polygon");
                                }}>
                                    <img src={"../../images/map-icons/polygon.webp"} className={"polygon small"} />
                                </div>
                            </Tooltip>

                            <Tooltip title="Draw a Circle">
                                <div className={`icon ${drawingMode === "circle" && "active"}`} onClick={() => {
                                    setDrawingMode("circle");
                                    setSelectedShape("circle");
                                }}>
                                    <div className={"circle"}></div>
                                </div>
                            </Tooltip>

                            <Tooltip title="Full Screen">
                                <div className={"icon"}>
                                    {
                                        !fullScreen ? <IconButton
                                                onClick={enterFullscreen}
                                            >
                                                <Fullscreen
                                                    fontSize={"large"}
                                                />
                                            </IconButton> :
                                            <IconButton
                                                onClick={exitFullscreen}
                                            >
                                                <FullscreenExit
                                                    fontSize={"large"}
                                                />
                                            </IconButton>
                                    }
                                </div>
                            </Tooltip>

                            <Tooltip title="Export location shapes coordinates into CSV">
                                <div className={"icon"} onClick={exportToCsv}>
                                    <img src={"../../images/map-icons/csv-export.png"} className={""} />
                                </div>
                            </Tooltip>
                        </div>

                        <div className={"icons"}>
                            <Tooltip title="Rotate Right">
                                <div className={`icon ${openLocationPopup && "active"}`} onClick={() => {rotateLocation(-1)}}>
                                    <img src={"../../images/map-icons/rotate-right.svg"} className={"rotate-svg"}/>
                                </div>
                            </Tooltip>

                            <Tooltip title="Redo">
                                <div className={"icon"} onClick={() => {undoRedoApiCall("redo")}}>
                                    <img src={"../../images/map-icons/redo.svg"} />
                                </div>
                            </Tooltip>

                            <Tooltip title="Place a Marker">
                                <div className={`icon ${drawingMode === "marker" && "active"}`} onClick={() => {
                                    setDrawingMode("marker");
                                    setSelectedShape("marker");
                                }}>
                                    <img src={"../../images/map-icons/marker.svg"} />
                                </div>
                            </Tooltip>

                            <Tooltip title="Draw a Rectangle">
                                <div className={`icon ${drawingMode === "rectangle" && "active"}`} onClick={() => {
                                    setDrawingMode("rectangle");
                                    setSelectedShape("rectangle");
                                }}>
                                    <div className={"rectangle"}></div>
                                </div>
                            </Tooltip>

                            <Tooltip title="Draw a Pentagon">
                                <div className={`icon ${selectedShape === "pentagon" && "active"}`} onClick={() => {
                                    setDrawingMode("polygon");
                                    setSelectedShape("pentagon");
                                }}>
                                    <img src={"../../images/map-icons/pentagon.svg"} />
                                </div>
                            </Tooltip>

                            <Tooltip title="Show / Hide Labels">
                                <div className={`icon ${showLocationNames && "active"}`} onClick={() => setShowLocationNames(!showLocationNames)}>
                                    <img src={"../../images/map-icons/text.webp"} className={"small"} />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                }

                {googleMap}
            </Grid>
        </Grid>
    </>
}

