import React, {useEffect, useState, useMemo, useRef} from "react";
import {useHistory} from "react-router-dom";
import {
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useTheme
} from "@mui/material";
import {$crud} from "./factories/CrudFactory";
import {Circle, GoogleMap, OverlayView, Polygon as PolygonComponent, Marker ,useLoadScript} from "@react-google-maps/api";
import {Libraries} from "@react-google-maps/api/dist/utils/make-load-script-url";
import {getBounds, getDistance, getLatitudeLongitude} from "./helpers";
import {LatLngLiteral} from "@googlemaps/google-maps-services-js";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider, DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default function PurchaseParking() {
    const history = useHistory();
    const [params, setParams] = useState<any>({});
    const [maps, setMaps] = useState<any>([]);
    const [selectedMapId, setSelectedMapId] = useState<any>("");
    const [selectedMapData, setSelectedMapData] = useState<any>(null);
    const [center, setCenter] = useState({
        lat: 32.940454,
        lng: -96.912492
    });
    const libraries = useMemo<Libraries>(() => ["geometry", "drawing", "places"], []);
    const {isLoaded} = useLoadScript({
        id: "birdview",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
        libraries
    });

    const [selectedLocation, setSelectedLocation] = useState<any>(null);

    useEffect(() => {
        setSelectedMapData(maps.find(s => s._id === selectedMapId));
    }, [selectedMapId]);

    useEffect(() => {
        if(selectedMapData){
            openBirdEye(selectedMapData);
            getLocations();
            setSelectedLocation(null);
        } else{
            setParams({});
        }

    }, [selectedMapData]);

    const getAllMaps = async () => {
        try{
            const {data: {total, maps}} = await $crud.get("maps", {
                attributes: "_id name area city zip"
            });

            setMaps(maps);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getAllMaps();
    }, []);

    const openBirdEye = async (map) => {
        let address;
        try{
            address = map.name + " " + map.area + " " + map.city + " " + map.zip;
            const apiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
            const location = await getLatitudeLongitude(address, apiKey);
            setCenter({
                lat: location.lat,
                lng: location.lng
            });
        } catch (e) {
            console.log(e);
        }
    };

    const [gMap, setGMap] = useState<google.maps.Map>();
    const [zoom, setZoom] = useState(20);
    const [mapTypeId, setMapTypeId] = useState<string>(google.maps.MapTypeId.SATELLITE);
    const [locations, setLocations] = useState<any>([]);
    const polygonRefs = useRef<Record<number, google.maps.Polygon>>({});
    const theme = useTheme();

    const getLocations = async () => {
        try{
            const {data: {locations}} = await $crud.get("locations", {addressId: selectedMapId});
            setLocations(locations);
        } catch (e) {
            console.log(e);
        }
    };

    const handleMapLocationClick = (locationId) => {
        if(selectedLocation && selectedLocation === locationId)
            setSelectedLocation(null);

        else
            setSelectedLocation(locationId);
    };

    const googleMap = isLoaded && center && <GoogleMap
        onLoad={setGMap}
        id="BirdEyeViewMap"
        center={center}
        zoom={zoom}
        mapTypeId={mapTypeId}
        onMapTypeIdChanged={() => gMap && setMapTypeId(gMap.getMapTypeId()!)}
        onZoomChanged={() => gMap && setZoom(gMap.getZoom()!)}
        mapContainerStyle={
            {
                width: "100%",
                height: "100%"
            }
        }
        clickableIcons={false}
        tilt={0}
        options={{
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeId: "satellite"
        }}
    >
        {
            locations.map((location, index) => {
                const {bounds, id, style, shape} = location;
                let point1, point2, heading, labelBounds;
                if(!["circle", "marker"].includes(shape)){
                    // @ts-ignore
                    [point1, point2] = bounds!.reduce<LatLngLiteral[]>((points, coords, i, array) => {
                        const nextPoint = array[i + 1] ?? array[0];
                        const dis = getDistance(coords?.lat, coords.lng, nextPoint?.lat, nextPoint.lng);
                        return !points.length || dis > getDistance(points[0]?.lat, points[0].lng, points[1]?.lat, points[1].lng) ? [coords, nextPoint] : points;
                    }, []);

                    heading = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(point1?.lat > point2?.lat ? point1 : point2), new google.maps.LatLng(point1?.lat > point2?.lat ? point2 : point1)) + 90;
                    if (heading > 0)
                        heading = heading - 180;
                    if (heading < -70)
                        heading = heading + 180;

                    let newBounds = getBounds(location.bounds!);
                    labelBounds = newBounds.bounds;
                }

                const isSelected = selectedLocation === location.id;
                const isBooked = location.booked;

                let fillColor = "#ffffcc";
                let strokeWeight = 0.5;
                let strokeColor = "#9d9d0e";

                if(isSelected){
                    fillColor = "#2d892d";
                    strokeWeight = 2;
                    strokeColor = "#0f0";
                }

                if(isBooked){
                    fillColor = "#9797a6";
                    strokeWeight = 2;
                    strokeColor = "#ccc";
                }

                return <React.Fragment key={id}>
                    {
                        !["circle", "marker"].includes(shape) && bounds.length && <>
                            <PolygonComponent
                                onLoad={polygon => polygonRefs.current[id!] = polygon}

                                onDrag={
                                    () => {
                                        setLocations(locations => locations.map(container => {
                                            if(container.shape != "marker"){
                                                const path = polygonRefs.current[container.id!]?.getPath()?.getArray()?.map(coords => coords.toJSON()) ?? container.bounds ?? [];
                                                const bounds = new google.maps.LatLngBounds();
                                                path?.forEach(coord => bounds.extend(coord));
                                                return {
                                                    ...container,
                                                    bounds: path,
                                                    coords: path ? bounds?.getCenter()?.toJSON() : undefined
                                                };
                                            }else {
                                                return container;
                                            }
                                        }));
                                    }
                                }

                                onMouseDown={
                                    () => {
                                        Object.entries(polygonRefs.current).forEach(([container_id, ref]) => {
                                            if (container_id !== id)
                                                ref.setOptions({editable: false});
                                        });
                                    }
                                }
                                onClick={() => !location.booked && handleMapLocationClick(location.id)}
                                options={
                                    {
                                        strokeWeight,
                                        strokeColor,
                                        fillColor,
                                        fillOpacity: 1,
                                        clickable: true,
                                        draggable: false,
                                        geodesic: true,
                                        editable: false
                                    }
                                }
                                key={index}
                                paths={bounds}
                            />
                            <OverlayView
                                bounds={labelBounds}
                                mapPaneName="overlayMouseTarget"
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    style={
                                        {
                                            width: "100%",
                                            height: "100%",
                                            transform: `rotateZ(${heading}deg)`,
                                        }
                                    }
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        style={
                                            {
                                                width: "100%",
                                                height: "100%",
                                                position: "relative",
                                            }
                                        }
                                    >
                                        <svg
                                            viewBox={`0 0 ${location?.Name?.length! * 6} 18`}
                                            style={
                                                {
                                                    width: "100%",
                                                    height: "100%",
                                                    position: "absolute"
                                                }
                                            }
                                        >
                                            <text fill={theme.palette.getContrastText(fillColor)} style={{fontSize: `${style?.labelFontSize || 10}px`}} x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">{location.Name}</text>
                                        </svg>
                                    </Grid>
                                </Grid>
                            </OverlayView>
                        </>
                    }
                    {
                        shape === "circle" && <>
                            <Circle
                                center={JSON.parse(location.circleCenter)}
                                radius={parseFloat(location.circleRadius)}
                                options={{
                                    fillColor,
                                    fillOpacity: 1,
                                    strokeWeight,
                                    strokeColor,
                                    clickable: true,
                                    zIndex: 1,
                                    draggable: false
                                }}
                                onClick={() => !location.booked && handleMapLocationClick(location.id)}

                            />
                            <OverlayView
                                position={JSON.parse(location.circleCenter)}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                            >
                                <div
                                    style={{
                                        background: 'transparent',
                                        padding: '3px',
                                        fontSize: '15px',
                                    }}
                                >
                                    {location.Name}
                                </div>
                            </OverlayView>
                        </>
                    }
                    {
                        shape === "marker" && <Marker
                            key={index}
                            position={JSON.parse(location.bounds)}
                            onClick={() => !location.booked && handleMapLocationClick(location.id)}
                        />
                    }
                </React.Fragment>;
            })
        }
    </GoogleMap>;

    return (
        <div style={{ display: 'flex'}}>
            <div
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: "calc(100vh - 146px)" }}
            >
                <Grid container spacing={1} style={{ flexGrow: 1 }}>
                    <Grid item className={"mt-2"} xs={12} style={{ flexGrow: 1 }}>
                        <Grid container spacing={2} justifyContent={"center"}>
                            {/*<Grid item>*/}
                            {/*    <Typography variant={"h6"}>Select Map To Continue</Typography>*/}
                            {/*</Grid>*/}

                            <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 250 }} size="medium">
                                    <InputLabel id="demo-select-small-label">Select Map</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={selectedMapId}
                                        label="Select Map"
                                        onChange={(e) => {setSelectedMapId(e.target.value)}}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            maps.map((map, key) => <MenuItem
                                                key={key}
                                                value={map._id}
                                            >
                                                {map.area + " - " + map.city}
                                            </MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <LocalizationProvider  dateAdapter={AdapterDayjs}>
                                    <DemoContainer  components={['DateTimePicker']}>
                                        <DateTimePicker
                                            disabled={!selectedMapId}
                                            label="Check In"
                                            value={params.checkInDateTime}
                                            onChange={(dateTime) => {
                                                setParams({
                                                    ...params,
                                                    checkInDateTime: dateTime
                                                });
                                            }}
                                            minDate={dayjs()}
                                            timezone={"UTC"}
                                            format="MM/DD/YYYY hh:mm A"
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']}>
                                        <DateTimePicker
                                            disabled={!params.checkInDateTime}
                                            label="Check Out"
                                            value={params.checkOutDateTime}
                                            onChange={(dateTime) => {
                                                setParams({
                                                    ...params,
                                                    checkOutDateTime: dateTime
                                                });
                                            }}
                                            minDate={dayjs(params.checkInDateTime)}
                                            timezone={"UTC"}
                                            format="MM/DD/YYYY hh:mm A"
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>

                            <Grid item>
                                <Grid container className={"h-100"} alignItems={"center"}>
                                    <Grid item>
                                        <Button
                                            disabled={!(params.checkOutDateTime && selectedLocation)}
                                            className={`${!(params.checkOutDateTime && selectedLocation) && "disabled"} reserve-btn`}
                                            onClick={() => history.push(`/purchase-parking/checkout/${selectedLocation}?mapName=${selectedMapData.area + " - " + selectedMapData.city}&checkInDateTime=${params.checkInDateTime}&&checkOutDateTime=${params.checkOutDateTime}`)}
                                        >Get Direction</Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item style={{ width: "100%", height: '100%' }}>
                        {
                            selectedMapId !== "" && <Grid height={"100%"} item xs={12}>
                                {googleMap}
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
